import { Grid } from "@material-ui/core";
import CustomModal from "components/ui/Modal/CustomModal";
import useForm from "hooks/useForm";
import useGetCourseModelDetails from "hooks/useGetCourseModelDetails";
import React, { useEffect } from "react";
import globalStyles from "styles/globalStyles";
import { createAdminUserErrors } from "utils/validationStates";
import CustomDate from "../../components/ui/DateLayout/CustomDate";
import { IViewCourseModel, ICourseModel } from "../../types/coursemodel";

export interface IViewCourseModelProps {
  viewDialog: boolean;
  handleClose: () => void;
  item: ICourseModel;
}

const ViewCourseModel = ({ viewDialog, handleClose, item }: IViewCourseModelProps) => {
  const initFormState: IViewCourseModel = {
    courseModelName: item.courseModelName,
    isActive: item.isActive,
    isDeleted: item.isDeleted,
    createdAt: item.createdAt,
  };
  const globalClasses = globalStyles();
  const { courseModelDetails, getCourseModelDetails } = useGetCourseModelDetails();

  const { setInputs, inputs } = useForm<IViewCourseModel>(initFormState, true, createAdminUserErrors, "EditAdminUser");

  useEffect(() => {
    getCourseModelDetails.mutate(item.id);
  }, [item]);
  useEffect(() => {
    if (courseModelDetails) {
      setInputs(() => ({
        ...courseModelDetails,
        CourseModelName: item.courseModelName,
        IsActive: item.isActive,
        IsDeleted: item.isDeleted,
        CreatAt: item.createdAt,
      }));
    }
  }, [courseModelDetails]);

  const handleToggleEditDialog = () => {
    handleClose?.();
  };
  return (
    <CustomModal
      open={viewDialog}
      handleCancel={handleToggleEditDialog}
      handleClose={handleToggleEditDialog}
      title="Course Model Details"
      confirmText="Save"
      showDividers={true}
      minWidth={700}
      showConfirm={false}
      showCancel={false}
      content={
        <>
          <div className={globalClasses.viewDetails}>
            <Grid container>
              <Grid item md={6} xs={12}>
                <p className="labelTop">Course Model Name</p>
                <p className="labelBottom overflow-scroll">{item.courseModelName}</p>
              </Grid>
              <Grid item md={6} xs={12}>
                <p className="labelTop">Date Created</p>
                <p className="labelBottom overflow-scroll">
                  {item.createdAt && <CustomDate date={item.createdAt.toString()} />}
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6} xs={12}>
                <p className="labelTop">Is Active</p>
                <p className="labelBottom">{item?.isActive ? "Yes" : "No"}</p>
              </Grid>
              <Grid item md={6} xs={12}>
                <p className="labelTop">Is Deleted</p>
                <p className="labelBottom">{item?.isDeleted ? "Yes" : "No"}</p>
              </Grid>
            </Grid>
          </div>
        </>
      }
    />
  );
};
export default ViewCourseModel;
