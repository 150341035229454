import { useTheme } from "@material-ui/core";
import redcircle from "assets/svg/redcircle.svg";
import { capitalize } from "lodash";
import React from "react";
import Select, { Theme } from "react-select";
import makeAnimated from "react-select/animated";
import customSelectStyles, { colourStyles } from "./customSelectStyles";

export interface CustomSelectProps<T> {
  placeholder?: string;
  defaultValue?: [] | {};
  labelText?: string;
  id: string;
  name: string;
  handleChange?: (selected: T) => void;
  value?: any;
  errorText?: string;
  showError?: boolean;
  className?: any;
  options?: T[];
  isLoading?: boolean;
  width?: number;
  paddingTop?: number;
  paddingBottom?: number;
  showBackground?: boolean;
  closeMenuOnSelect?: boolean;
  isMulti?: boolean;
  height?: number | string;
  minheight?: number;
  backgroundColor?: string;
  border?: string;
  getOptionLabel?: (option: T) => string;
  getOptionValue?: (option: T) => string;
  marginTop?: number;
  maxMenuHeight?: number;
  disabled?: boolean;
}
function CustomSelect<T>({
  getOptionLabel,
  getOptionValue,
  showBackground = false,
  isMulti,
  placeholder,
  backgroundColor,
  width,
  height,
  minheight,
  isLoading,
  paddingTop,
  border,
  closeMenuOnSelect = true,
  paddingBottom,
  defaultValue,
  labelText,
  options,
  id,
  name,
  handleChange,
  value,
  errorText,
  showError,
  disabled,
  marginTop,
  maxMenuHeight,
  className,
}: CustomSelectProps<T>) {
  const appTheme = useTheme();
  const classes = customSelectStyles();
  const animatedComponents = makeAnimated();
  return (
    <>
      {labelText && (
        <label className={classes.selectinputLabel} htmlFor={id}>
          {capitalize(labelText)}
        </label>
      )}
      <Select
        defaultValue={defaultValue}
        placeholder={isLoading ? "Loading.." : placeholder}
        options={options}
        styles={colourStyles(
          showError,
          width,
          paddingTop,
          paddingBottom,
          showBackground,
          height,
          minheight,
          backgroundColor,
          border,
          marginTop,
        )}
        value={value}
        menuPortalTarget={document.body}
        name={value}
        isMulti={isMulti}
        components={animatedComponents}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={disabled}
        maxMenuHeight={maxMenuHeight}
        onChange={handleChange}
        isLoading={isLoading}
        // getOptionValue={getOptionValue}
        // getOptionLabel={getOptionLabel}
        // getOptionLabel={option => option.label}
        // getOptionValue={option => option.value}
        theme={(theme: Theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: appTheme.palette.primary.main,
          },
        })}
      />

      {showError && (
        <div className={classes.selectErrorTextContainer}>
          <div className={classes.selectinputerrorIcon}>
            <img src={redcircle} />
          </div>
          <div>
            {" "}
            <small className={classes.selectErrorText}>{errorText}</small>
          </div>
        </div>
      )}
    </>
  );
}
export default CustomSelect;
