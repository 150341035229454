import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

const loginStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      marginTop: theme.spacing(12),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "444px",
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(10),
      },
      "& a": {
        textDecoration: "none !important",
      },
      "& .proceed": {
        marginTop: 8,
        color: theme.palette.text.secondary,
        fontWeight: 400,
      },
      "& .forgot-password": {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
      "& img": {
        margin: "auto",
        textAlign: "center",
        width: "200px",
      },
    },

    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    formRoot: {
      width: "105%", // Fix IE 11 issue.
      alignItems: "center",
      backgroundColor: theme.palette.primary.contrastText,
      padding: theme.spacing(4),
      borderRadius: 8,
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(2.5),
      boxShadow: "0 1px 6px 0 rgb(32 33 36 / 13%)",
      "& h1": {
        fontWeight: "600",
        fontSize: 24,
      },
    },
  }),
);
export default loginStyles;
