import { TextField, TextFieldProps, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";
import redcircle from "../../../assets/svg/redcircle.svg";
import { ITheme } from "../../../theme";
import { CustomCircleLoader } from "../Loader/CustomCircleLoader";
import customInputStyle from "./customInputStyle";

export interface CustomInputProps {
  type?: string;
  labelText?: string;
  showError?: boolean;
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
  errorText?: string;
  text?: string;
  endIcon?: any;
  id?: string;
  startIcon?: JSX.Element;
  textfieldProps?: TextFieldProps;
  inputHeight?: number | string;
  className?: string;
  name?: string;
  inputWidth?: number | string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  customButtonText?: JSX.Element;
  multiline?: boolean;
  loading?: boolean;
  defaultValue?: string;
  position?: React.CSSProperties["position"];
  rows?: number;
  // showDefaultBorder?: boolean;
  backgroundColor?: React.CSSProperties["backgroundColor"];
  useGreyBackground?: boolean;
}

const CustomInput: FC<CustomInputProps> = ({
  labelText,
  errorText,
  value,
  endIcon,
  placeholder = "Text Input",
  type = "text",
  showError = false,
  disabled = false,
  textfieldProps,
  id = "",
  startIcon,
  inputHeight,
  className,
  rows,
  name,
  handleChange,
  handleKeyPress,
  backgroundColor,
  useGreyBackground = false,
  inputWidth,
  customButtonText,
  multiline,
  defaultValue,
  loading,
  // showDefaultBorder = true,
  position = "relative",
}) => {
  const appTheme = useTheme<ITheme>();
  const classes = customInputStyle();
  return (
    <div className={classes.inputContainer}>
      {labelText && (
        <label style={{ position: position }} className={clsx(classes.inputLabel)} htmlFor={id}>
          {labelText}
        </label>
      )}{" "}
      <TextField
        id={id}
        defaultValue={defaultValue}
        InputProps={{
          // endAdornment: endIcon,
          defaultValue: defaultValue,
          startAdornment: startIcon,
          endAdornment:
            endIcon ||
            (loading && (
              <div className="d-flex justify-content-center">
                {startIcon && <div>{startIcon}</div>}
                {loading && <div>{<CustomCircleLoader />}</div>}
              </div>
            )),
          className: className,
          classes: {
            root: classes.root,
            input: multiline ? classes.multilineInput : classes.input,
            notchedOutline: classes.notchedOutline,
          },
          style: {
            width: inputWidth,
            backgroundColor: useGreyBackground ? appTheme.palette.variants.grey : backgroundColor,
          },
        }}
        inputProps={{
          style: {
            height: inputHeight,

            marginTop: 0,
          },
        }}
        minRows={rows}
        name={name}
        {...textfieldProps}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        error={showError}
        autoComplete="new-password" //to disable autocomplete
        variant="outlined"
        value={value}
        multiline={multiline}
        margin="normal"
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        fullWidth
      />
      {showError && (
        <div className={classes.inputErrorContainer}>
          <div className={classes.inputerrorIcon}>
            <img src={redcircle} />
          </div>
          <div>
            {" "}
            <small className={classes.errorLabel}>{errorText}</small>
          </div>
        </div>
      )}
      {customButtonText && (
        <div className={classes.inputErrorContainer}>
          <div className={classes.inputerrorIcon}></div>
          <div> {customButtonText}</div>
        </div>
      )}
    </div>
  );
};
export default CustomInput;
