import { Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TuneIcon from "@material-ui/icons/Tune";
import searchicon from "assets/svg/searchicon.svg";
import React from "react";
import { entries } from "../../../constants/appConstants";
import useMobile from "../../../hooks/useMobile";
import { IOptions } from "../../../types/Gen";
import CustomActionButton from "../../ui/CustomActionButton/CustomActionButton";
import CustomInput from "../../ui/CustomInput/CustomInput";
import CustomSelect from "../../ui/CustomSelect/CustomSelect";
const useStyles = makeStyles((theme) => ({
  pageSectionSearch: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: theme.spacing(0.5),
    },
    marginLeft: theme.spacing(5),
    marginTop: "-30px",
  },
  filterRoot: {
    paddingTop: 25,
    paddingLeft: 20,
    paddingRight: 20,
    "& .subTitle": {
      marginTop: 10,
    },

    "& .showing": {
      marginTop: 15,
      fontWeight: "normal",
      marginLeft: 20,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        display: "none",
      },
    },
    "& .filterButton": {
      marginTop: 5,
      marginLeft: 20,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
  },
  rightSection: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
}));
export interface IFilterMenuProps {
  filterLabel: string;
  caption: string;
  totalCount?: number | string;
  showSearch?: boolean;
  searchName: string;
  searchplaceholder?: string;
  searchValue?: string;
  clearSearch?: () => void;
  showFilter?: boolean;
  selectedEntry?: IOptions;
  showEntries?: boolean;
  handleSelectEntriesChange?: (e: IOptions) => void;
  handleSearchInputChange?: (e: React.ChangeEvent<HTMLInputElement>, name?: string, label?: string) => void;
  searchLabelText?: string;
  openFilterMenu?: () => void;
}

export const FilterMenu = (props: IFilterMenuProps) => {
  const classes = useStyles();
  const { isMobile } = useMobile();
  const appTheme = useTheme();
  return (
    <>
      <Grid container className={classes.filterRoot}>
        {props.caption && props.caption !== "" && (
          <Grid item md={3}>
            <div className="subTitle">
              <h5>
                <b>{props.caption}</b>
                <span className="ms-3" style={{ fontSize: "14px", color: "#9DA9B3", marginRight: "1rem" }}>
                  {props.totalCount}
                </span>
              </h5>
            </div>
          </Grid>
        )}

        <Grid item md={9}>
          <div className={classes.rightSection}>
            <div>
              <CustomInput
                endIcon={<img src={searchicon} />}
                placeholder={props.searchplaceholder || "Search"}
                inputHeight={10}
                inputWidth={isMobile ? 300 : 300}
                name={props.searchName}
                value={props.searchValue}
                handleChange={(e) => props.handleSearchInputChange?.(e, props.searchName, props.searchLabelText)}
              />
            </div>
            {props.showFilter && (
              <div style={{ display: "flex" }}>
                <div className="filterButton">
                  <CustomActionButton
                    startIcon={<TuneIcon />}
                    text={props.filterLabel}
                    show={props.showFilter}
                    onClick={props.openFilterMenu}
                    borderColor={appTheme.palette.grey[400]}
                    backgroundColor={appTheme.palette.primary.contrastText}
                    width={120}
                  />
                </div>
              </div>
            )}
            <div className=" ms-auto">
              {props.showEntries && (
                <div className="d-flex">
                  <div className="showing">Showing:&nbsp;</div>
                  <div>
                    <CustomSelect<any>
                      options={entries}
                      name={`table-entries-${props.searchName}`}
                      id={`table-entries-${props.searchName}`}
                      value={props.selectedEntry}
                      defaultValue={entries?.[0]}
                      height={40}
                      width={200}
                      paddingTop={2}
                      handleChange={(e) => props?.handleSelectEntriesChange?.(e)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
