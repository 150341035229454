import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { ITheme } from "../theme";
import { linkColor } from "../theme/default";

const globalStyles = makeStyles((theme: ITheme) =>
  createStyles({
    "@global": {
      "*": {
        margin: 0,
        padding: 0,
      },
      body: {
        backgroundColor: "#F4F5F6",
      },
      html: {
        fontSize: 12,
        [theme.breakpoints.up("md")]: {
          fontSize: 14,
        },
      },
      a: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 600,
        fontSize: "0.875rem",
        textDecoration: "none",
      },
      label: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: "0.875rem",
      },
      // '*::-webkit-scrollbar': {
      //     width: '0.4rem',
      //     height: '0.4rem'
      // },
      // '*::-webkit-scrollbar-track': {
      //     '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      // },
      // '*::-webkit-scrollbar-thumb': {
      //     backgroundColor: 'rgba(250,250,250,.4)',
      //     borderRadius: '8px',
      // },
      "*::-webkit-scrollbar": {
        "-webkit-appearance": "none",
        width: "0.4rem",
      },
      "*::-webkit-scrollbar-thumb": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(255,255,255,.5)",
        backgroundColor: "rgba(0,0,0,.3)",
      },
      "iframe, objectFit, embed": {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100% !important",
      },
    },
    selectRootPagination: {
      "@media only screen and (max-width:600px)": {
        position: "absolute",
        left: "154px",
      },
    },
    proceedText: {
      "& label": {
        color: linkColor,
        cursor: "pointer",
        margin: 0,
        fontSize: 14,
        fontWeight: 400,
      },
    },
    viewDetails: {
      marginTop: 10,
      "& .labelTop": {
        marginBottom: 5,
        color: theme.palette.text.secondary,
        fontSize: 12,
      },
      "& .labelBottom": {
        color: theme.palette.variants.primaryBlack,
        fontWeight: 400,
        fontSize: 14,
      },
    },
    mobileDrawer: {
      "@media screen and (min-width: 0px) and (max-width: 600px)": {
        display: "block",
      },

      "@media screen and (min-width: 401px) and (max-width: 1024px)": {
        display: "none",
      },
    },

    uploadPreview: {},
  }),
);
export default globalStyles;
