import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { IListTableHeader } from 'types/listtable';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    fontWeight:600,
    fontSize:12,
    paddingLeft:theme.spacing(4)
  },
  body: {
    paddingLeft:theme.spacing(4)

  },
}))(TableCell);

interface HeaderProps {
  headers?:IListTableHeader[]
}
const Header: React.FC<HeaderProps> = ({headers}) => {

  return (
    <TableHead>
      <TableRow>
        {headers?.map((row:IListTableHeader, index:number) => (
          <StyledTableCell key={index}>{row.name?.toUpperCase()}</StyledTableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}
export default Header
