import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

const settingsStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    overviewTabs: {
      marginTop: theme.spacing(2),  
      height:"100%"
    },
  }),
);
export default settingsStyles;
