import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { drawerWidth } from "../../constants/appConstants";
import { ITheme } from "../../theme";

const sidenavStyles = makeStyles((theme: ITheme) =>
  createStyles({
    appBar: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        flexShrink: 0,
        width: drawerWidth,
      },
    },
    drawerPaper: {
      backgroundColor: theme.palette.primary.dark,
      borderRight: "none",
      width: drawerWidth,
    },
    groupMenu: {},
    logoContainer: {
      display: "flex",
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(0.5),
      position: "absolute",
      "& img": {
        width: "75%",
      },
    },
    logoText: {
      color: theme.palette.primary.main,
      fontSize: 18,
      marginLeft: 5,
      marginTop: 8,
    },
    menuButton: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
      marginRight: theme.spacing(2),
    },
    root: {
      display: "flex",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
  }),
);
export default sidenavStyles;
