import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import { getCourseProgramDetailsApiService } from "../services/coursemodel";
import { ICourseModel } from "../types/coursemodel";
import { INormalResponse } from "../types/response";
import useHandleError from "./useHandleError";

const useGetCourseModelDetails = () => {
  const [courseModelDetails, setcourseModelDetails] = useState<ICourseModel>();
  const { handleAppError } = useHandleError();
  const { addToast } = useToasts();

  const getCourseModelDetails = useMutation(getCourseProgramDetailsApiService, {
    onSuccess: (res: AxiosResponse<INormalResponse<ICourseModel>>) => {
      if (res.data.hasErrors) {
        addToast(res.data?.description ?? "An error occurred", {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        const { payload } = res.data;
        setcourseModelDetails(payload);
      }
    },
    onError: (axiosError: AxiosError) => {
      handleAppError(axiosError, null);
    },
  });

  return {
    courseModelDetails,
    getCourseModelDetails,
  };
};

export default useGetCourseModelDetails;
