import { TableBody, TableRow, Typography } from "@material-ui/core";
import PageLayout from "components/hoc/PageLayout";
import Aux from "components/hoc/_Aux";
import { FilterMenu } from "components/shared/FilterMenu/FilterMenu";
import CustomButton from "components/ui/CustomButton/CustomButton";
import VigilearnPaginationApi from "components/ui/Pagination/VigilearnPaginationApi";
import { CustomTableCell } from "components/ui/Table/CustomTableCell";
import ListTable from "components/ui/Table/ListTable";
import { initPager } from "constants/appConstants";
import { dummyRequestData } from "constants/dummyRequests";
import { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getAllSettingApiService } from "services/settings";
import { IOptions } from "types/Gen";
import { IListMetaData } from "types/listtable";
import { IPager } from "types/paginationDefault";
import { IRequests } from "types/requests";
import { ISettings } from "types/settings";
import { useDebounce } from "use-debounce";
import { returnObjValue } from "utils/otherUtils";

export interface SettingsProps {
  returnData?: (input: IPager) => void;
}

const Settings = ({ returnData }: SettingsProps) => {
  const [pager, setpager] = useState<IPager>(initPager);
  const [listMetaData, setlistMetaData] = useState<IListMetaData>({ isSearching: true, isError: false });
  const [pageOfItems, setpageOfItems] = useState<ISettings[]>([]);
  const [settingsearchData, setsettingsearchData] = useState<IRequests>({
    ...dummyRequestData,
  });
  const history = useHistory();
  const [item, setitem] = useState<ISettings>();
  const [refresh, setrefresh] = useState(false);
  const [settingsearchKeyword, setsettingsearchKeyword] = useState<string>();
  const [settingsearchDataKeyword] = useDebounce(settingsearchKeyword, 700);
  const openFilterMenu = () => {};

  const handleChangePageParent = (newPage: number, pageOfItems: ISettings[], newPager: IPager) => {
    setpageOfItems(pageOfItems);
    setpager(newPager);
  };

  const updateSearch = (listMetaData: IListMetaData) => {
    setlistMetaData(listMetaData);
  };
  useEffect(() => {
    // the search keyword has been changed...
    if (settingsearchDataKeyword || settingsearchDataKeyword === "") {
      setsettingsearchData((prevState) => ({
        ...prevState,
        searching: true,
        searchWord: settingsearchKeyword,
      }));
    }
  }, [settingsearchDataKeyword]);

  useEffect(() => {
    if (returnData) {
      returnData(pager);
    }
  }, [pager]);

  const handleSelectEntriesChange = (e: IOptions) => {
    setsettingsearchData((prevState) => ({
      ...prevState,
      searching: true,
      pageSize: e.value,
    }));
  };
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setsettingsearchKeyword(e.target.value);
  };

  const openView = (item: ISettings) => {
    setitem(item);
  };

  const handleOpenCreate = () => {
    history.push("/app/settings/create");
  };
  const returnParsed = (item: any, key: string) => {
    if (item) {
      const parsed = Object.fromEntries(
        item
          .replace("{", "")
          .replace("}", "")
          .split(",")
          .map((i: string) => i.split(":")),
      );
      return parsed?.[`\"${key}\"`]?.replace(/"/g, "");
    } else {
      return "-";
    }
  };

  // const returnObj = (item: any, key: string) => {
  //   const obj = getObjectFromString(item);
  //   return obj?.[key];
  // };

  const handleEditSettings = (item: ISettings) => {
    history.push(`/app/settings/edit/${item.instituitonId}`);
  };
  return (
    <Aux>
      <PageLayout
        pageTitle={""}
        topSection={
          <FilterMenu
            caption=""
            showFilter={false}
            showSearch={true}
            showEntries={true}
            openFilterMenu={openFilterMenu}
            searchName="keyword"
            handleSelectEntriesChange={handleSelectEntriesChange}
            searchValue={settingsearchKeyword}
            handleSearchInputChange={handleSearchInputChange}
            filterLabel={"Filter by"}
          />
        }
        rightActions={<CustomButton onClick={handleOpenCreate} className="mt-3" text="Create Settings" />}
      >
        <ListTable
          headers={[
            { name: "University Name" },
            { name: "Email" },
            { name: "Api Key" },
            { name: "Api Url" },
            { name: " ApplicationPortal URL" },
            { name: "Tenancy Url" },
            { name: "" },
          ]}
          items={pageOfItems}
          marginLeft={0}
          marginRight={18}
          noRecordDescription="You currently have no settings"
          isSearching={listMetaData?.isSearching}
          showError={listMetaData?.isError}
          content={
            <TableBody>
              {pageOfItems?.map((item: ISettings, index: number) => (
                <TableRow data-id="row" key={index} hover>
                  <CustomTableCell style={{ minWidth: 300 }} align="left">
                    {item.instituitonName}
                  </CustomTableCell>
                  <CustomTableCell style={{ minWidth: 300 }} align="left">
                    {truncate(item.email, { length: 25 })}
                  </CustomTableCell>
                  <CustomTableCell style={{ minWidth: 300 }} align="left">
                    {item.schoolSettings && item.schoolSettings.length > 10
                      ? returnObjValue(item.schoolSettings, "ApiKey")
                      : item.schoolSettings}
                  </CustomTableCell>
                  <CustomTableCell style={{ minWidth: 300 }} align="left">
                    {item.schoolSettings && item.schoolSettings.length > 10
                      ? returnObjValue(item.schoolSettings, "ApiUrl")
                      : item.schoolSettings}
                  </CustomTableCell>
                  <CustomTableCell>
                    {item.applySettings && item.applySettings.length > 10
                      ? returnObjValue(item.applySettings, "ApplicationPortalURL")
                      : item.applySettings}
                  </CustomTableCell>
                  <CustomTableCell style={{ minWidth: 300 }} align="left">
                    {item.applySettings && item.applySettings.length > 10
                      ? returnObjValue(item.applySettings, "TenancyUrl")
                      : item.applySettings}
                  </CustomTableCell>

                  <CustomTableCell style={{ minWidth: 300 }} align="left">
                    <Typography
                      onClick={() => handleEditSettings(item)}
                      className="cursor-pointer"
                      color="primary"
                      variant="button"
                    >
                      View settings
                    </Typography>
                  </CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <div className="w-100">
          <VigilearnPaginationApi<ISettings>
            itemsCount={pager.totalItems}
            handleChangePageParent={handleChangePageParent}
            items={pageOfItems}
            searchData={settingsearchData}
            updateSearch={updateSearch}
            listMetaData={listMetaData}
            refresh={refresh}
            service={getAllSettingApiService}
          />
        </div>
      </PageLayout>
    </Aux>
  );
};

export default Settings;
