import { Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { drawerWidth } from "../constants/appConstants";

const wrapperStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            [theme.breakpoints.up("sm")]: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`
              },
            paddingTop: theme.spacing(4),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            marginTop:theme.spacing(8),
           [theme.breakpoints.down("md")]: {
                paddingTop: theme.spacing(0),
                paddingLeft: theme.spacing(2),
                marginTop:theme.spacing(10)
              },
        },
    }),
);
export default wrapperStyles