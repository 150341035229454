import { TableCell, TableCellProps } from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme: Theme) => ({
  head: {},
  body: {
    color: theme.palette.text.primary,
    fontWeight:400
  },
}))(TableCell);

export interface ICustomTableCellProps extends TableCellProps {
  children?: React.ReactChild | React.ReactChild[] | JSX.Element | string;
}

export const CustomTableCell = ({ children }: ICustomTableCellProps) => {
  return <StyledTableCell>{children}</StyledTableCell>;
};
