import { api } from "../constants/apiConstants";
import { dummyRequestData } from "../constants/dummyRequests";
import { IRequests } from "../types/requests";
import { ISettingsRequest } from "../types/settings";
import { returnBaseUrl } from "../utils/serviceUtils";
import Axios from "./AxiosInstance";


export const getAllSettingApiService = (payload: IRequests = dummyRequestData) => {
  let newPayload  = dummyRequestData
  if(payload.pageIndex && payload.pageIndex !== null && payload.pageIndex !== undefined){
    newPayload = {
      ...newPayload,
      pageIndex : payload.pageIndex
    }
  }
  if(payload.fromDate && payload.fromDate !== null && payload.fromDate !== undefined){
    newPayload = {
      ...newPayload,
      fromDate : payload.fromDate
    }
  }
  if(payload.toDate && payload.toDate !== null && payload.toDate !== undefined){
    newPayload = {
      ...newPayload,
      toDate : payload.toDate
    }
  }
  if(payload.searchWord && payload.searchWord !== null && payload.searchWord !== undefined){
    newPayload = {
      ...newPayload,
      searchWord : payload.searchWord
    }
  }
  if(payload.pageSize && payload.pageSize !== null && payload.pageSize !== undefined){
    newPayload = {
      ...newPayload,
      pageSize : payload.pageSize
    }
  }
  
  return Axios.post(returnBaseUrl(api.SETTING,api.GET_ALL_SETTING),newPayload);
};

export const addSettingsApiService = (payload : ISettingsRequest) => {
  // stringfy the payload because that is what the api is expecting
  return Axios.post(returnBaseUrl(api.SETTING,api.CREATE_SETTING),payload);
};
export const viewSettingsDetailsApiService = (institutionId: number) => {
  // stringfy the payload because that is what the api is expecting
  return Axios.get(returnBaseUrl(api.SETTING,api.GET_SETTING,institutionId as unknown as string));
};
export const updateSettingsApiService = (payload : ISettingsRequest, id? : number) => {
  // stringfy the payload because that is what the api is expecting
  return Axios.put(returnBaseUrl(api.SETTING,`${api.UPDATE_SETTING}/${id}`),payload);
};