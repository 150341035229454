const menuitems: any = [
  {
    id: "overview",
    title: "Overview",
    type: "item",
    url: "/app/home",
    icon: "fi-rr-home",
    auth: "",
    description: "",
  },
  {
    id: "students",
    type: "children",
    title: "Prospectives",
    icon: "fi fi-rr-link",
    parentType: "childrenParent",
    children: [
      {
        id: "students",
        title: "Applicants",
        type: "item",
        url: "/app/applicants",
        icon: "fi-rr-user",
        auth: "",
      },
      {
        id: "signups",
        title: "Signups",
        type: "item",
        url: "/app/signups",
        icon: "fi-rr-user",
        auth: "",
      },
    ],
  },
  {
    id: "instructor",
    title: "Instructors",
    type: "item",
    url: "/app/instructors",
    icon: "fi-rr-users",
    auth: "",
  },
  {
    id: "usermanagement",
    type: "children",
    title: "User Management",
    icon: "fi fi-rr-link",
    parentType: "childrenParent",
    children: [
      {
        id: "adminusers",
        title: "Admin Users",
        type: "children-item",
        url: "/app/adminusers",
        icon: "fi fi-rr-comment",
      },
      {
        id: "rolespermissions",
        title: "Roles & Permissions",
        type: "children-item",
        url: "/app/rolespermissions",
        icon: "fi fi-rr-comment-alt",
        description: "",
      },
    ],
  },
  {
    id: "institutions",
    title: "Institutions",
    type: "item",
    url: "/app/institutions",
    icon: "fi-rr-user",
    auth: "",
  },
  {
    id: "courses",
    title: "Courses",
    type: "item",
    url: "/app/courses",
    icon: "fi-rr-document",
    auth: "",
  },
  {
    id: "filtercategories",
    title: "Filter Categories",
    type: "children",
    icon: "fi-rr-document",
    parentType: "childrenParent",
    children: [
      {
        id: "specialization",
        title: "Specialization",
        type: "children-item",
        url: "/app/specialization",
      },
      {
        id: "country",
        title: "Country",
        type: "children-item",
        url: "/app/country",
      },
      {
        id: "coursetype",
        title: "Course Type",
        type: "children-item",
        url: "/app/coursetype",
      },
      {
        id: "methodofstudy",
        title: "Method of Study",
        type: "children-item",
        url: "/app/methodofstudy",
      },
      {
        id: "degree",
        title: "Degree",
        type: "children-item",
        url: "/app/degree",
      },
      {
        id: "duration",
        title: "Duration",
        type: "children-item",
        url: "/app/duration",
      },
      {
        id: "cohorts",
        title: "Cohort",
        type: "children-item",
        url: "/app/cohort",
      },
    ],
  },
  // {
  //   id: "curriculum",
  //   title: "Curriculum",
  //   type: "item",
  //   url: "/app/curriculum",
  //   icon: "fi-rr-users",
  //   auth: "",
  // },
  {
    id: "websitecontent",
    type: "children",
    title: "Website Content",
    icon: "fi fi-rr-link",
    children: [
      {
        id: "testimonials",
        title: "Testimonials",
        type: "children-item",
        url: "/app/testimonials",
        icon: "fi fi-rr-comment",
      },
    ],
  },
  {
    id: "products",
    title: "Products",
    type: "item",
    url: "/app/products",
    icon: "fi-rr-document",
    auth: "",
  },
  {
    id: "usermanagement",
    title: "Categories",
    type: "item",
    url: "/app/categories",
    icon: "fi-rr-document",
    auth: "",
  },
  {
    id: "institutions",
    title: "Scholarships",
    type: "item",
    url: "/app/scholarships",
    icon: "fi-rr-document",
    auth: "",
  },
  {
    id: "audittrail",
    title: "AuditTrail",
    type: "item",
    url: "/app/audittrail",
    icon: "fi-rr-document",
    auth: "",
  },
  {
    id: "settings",
    title: "Settings",
    type: "children",
    url: "/app/settings",
    icon: "fi-rr-document",
    auth: "",
    // children: [
    //   {
    //     id: "institutionsettings",
    //     title: "Institution settings",
    //     type: "children-item",
    //     url: "/app/settings",
    //   },
    // ],
  },
];
export default menuitems;
