import mainbrand from "assets/svg/mainbrand.svg";
import CustomButton from "components/ui/CustomButton/CustomButton";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import authLayoutStyles from "styles/components/authLayoutStyles";
import "./error.css";
export const ErrorPage = ({ caption, description }: { caption: string; description: string }) => {
  const history = useHistory<any>();
  const classes = authLayoutStyles();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <div>
        <section className="page_404">
          <div className="container">
            <div className="row">
              <Link to="/" className="text-decoration-none">
                <div className={classes.mainBrand404}>
                  <div>
                    <img src={mainbrand} alt="mainBrand" />
                  </div>
                  </div>
              </Link>
              <div className="col-sm-12 ">
                <div className="text-center">
                  <div className="four_zero_four_bg">
                    <h1 className="text-center ">404</h1>
                  </div>

                  <div className="contant_box_404">
                    <h3 className="h2">{caption}</h3>

                    <p>{description}</p>

                    <div className="d-flex justify-content-center">
                      <div className="m-2">
                        <CustomButton text="Back" default width={150} height={50} onClick={handleGoBack} />
                      </div>

                      <div className="m-2">
                        <CustomButton text="Refresh" width={150} height={50} onClick={() => window.location.reload()} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
