import { MenuItem, TableBody, TableRow, Typography } from "@material-ui/core";
import { AxiosError, AxiosResponse } from "axios";
import PageLayout from "components/hoc/PageLayout";
import Aux from "components/hoc/_Aux";
import iconmore from "assets/svg/iconmore.svg";
import { FilterMenu } from "components/shared/FilterMenu/FilterMenu";
import CustomButton from "components/ui/CustomButton/CustomButton";
import CustomMenuWrapper from "components/ui/CustomMenuWrapper/CustomMenuWrapper";
import VigilearnPaginationApi from "components/ui/Pagination/VigilearnPaginationApi";
import { CustomTableCell } from "components/ui/Table/CustomTableCell";
import ListTable from "components/ui/Table/ListTable";
import { initPager } from "constants/appConstants";
import { dummyRequestData } from "constants/dummyRequests";
import useHandleError from "hooks/useHandleError";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { IOptions } from "types/Gen";
import { IListMetaData } from "types/listtable";
import { IPager } from "types/paginationDefault";
import { IRequests } from "types/requests";
import { INormalResponse } from "types/response";
import { useDebounce } from "use-debounce";
import CustomDate from "../../components/ui/DateLayout/CustomDate";
import { deleteCourseModelApiService, getAllCourseModelsApiService } from "../../services/coursemodel";
import { ICourseModel, ICourseModelResponse } from "../../types/coursemodel";
import AddCourseModel from "./AddCourseModel";
import ViewCourseModel from "./ViewCourseModel";
import EditCourseModel from "./EditCourseModel";

export interface CourseModelProps {
  returnData?: (input: IPager) => void;
}

const CourseModel = ({ returnData }: CourseModelProps) => {
  const [pager, setpager] = useState<IPager>(initPager);
  const [listMetaData, setlistMetaData] = useState<IListMetaData>({ isSearching: true, isError: false });
  const [pageOfItems, setpageOfItems] = useState<ICourseModelResponse[]>([]);
  const [coursemodelsearchData, setcoursemodelsearchData] = useState<IRequests>({
    ...dummyRequestData,
  });
  const [item, setitem] = useState<ICourseModelResponse>();
  const [refresh, setrefresh] = useState(false);
  const [coursemodelKeyword, setsettingsearchKeyword] = useState<string>();
  const [coursemodelsearchDataKeyword] = useDebounce(coursemodelKeyword, 700);
  const openFilterMenu = () => {};
  const [openDialog, setopenDialog] = useState(false);
  const [editDialog, seteditDialog] = useState(false);
  const [viewDialog, setviewDialog] = useState(false);
  const handleChangePageParent = (newPage: number, pageOfItems: ICourseModelResponse[], newPager: IPager) => {
    setpageOfItems(pageOfItems);
    setpager(newPager);
  };

  const { addToast } = useToasts();
  const { handleAppError } = useHandleError();

  const updateSearch = (listMetaData: IListMetaData) => {
    setlistMetaData(listMetaData);
  };
  useEffect(() => {
    // the search keyword has been changed...
    if (coursemodelsearchDataKeyword || coursemodelsearchDataKeyword === "") {
      setcoursemodelsearchData((prevState) => ({
        ...prevState,
        searching: true,
        searchWord: coursemodelKeyword,
      }));
    }
  }, [coursemodelsearchDataKeyword]);

  useEffect(() => {
    if (returnData) {
      returnData(pager);
    }
  }, [pager]);

  const handleSelectEntriesChange = (e: IOptions) => {
    setcoursemodelsearchData((prevState) => ({
      ...prevState,
      searching: true,
      pageSize: e.value,
    }));
  };
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setsettingsearchKeyword(e.target.value);
  };

  const handleOpenCreate = () => {
    setopenDialog(true);
  };

  const handleClose = () => {
    setopenDialog(false);
    setrefresh(false);
    setrefresh(true);
  };

  const handleCloseEdit = () => {
    setrefresh(false);
    setrefresh(true);
    seteditDialog(false);
  };
  const openView = (item: ICourseModel) => {
    setitem(item);
    setviewDialog(true);
  };
  const openEdit = (item: ICourseModel) => {
    setitem(item);
    seteditDialog(true);
  };

  const handleCloseView = () => {
    setviewDialog(false);
  };

  const deleteCourseModel = (item: ICourseModel) => {
    console.log("delete", item);

    deleteCourseModelApiService(item?.id)
      .then((res: AxiosResponse<INormalResponse>) => {
        // setcreating(false);
        if (res.data.hasErrors) {
          addToast(res.data.description, {
            appearance: "error",
          });
        } else {
          addToast("Course Type Deleted", {
            appearance: "success",
          });
          setcoursemodelsearchData(() => ({
            ...dummyRequestData,
            searching: true,
          }));
        }
      })
      .catch((axiosError: AxiosError) => {
        handleAppError(axiosError, null);
        // setcreating(false);
      });
  };

  return (
    <Aux>
      <PageLayout
        pageTitle={""}
        topSection={
          <FilterMenu
            caption=""
            showFilter={false}
            showSearch={true}
            showEntries={true}
            openFilterMenu={openFilterMenu}
            searchName="keyword"
            handleSelectEntriesChange={handleSelectEntriesChange}
            searchValue={coursemodelKeyword}
            handleSearchInputChange={handleSearchInputChange}
            filterLabel={"Filter by"}
          />
        }
        rightActions={<CustomButton onClick={handleOpenCreate} className="mt-3" text="Add Course Model" />}
      >
        <ListTable
          headers={[{ name: "Course Model Type" }, { name: "Date Created" }, { name: "" }]}
          items={pageOfItems}
          noRecordDescription="You currently have no course models"
          isSearching={listMetaData?.isSearching}
          showError={listMetaData?.isError}
          content={
            <TableBody>
              {pageOfItems?.map((item: ICourseModelResponse, index: number) => (
                <TableRow data-id="row" key={index} hover>
                  <CustomTableCell align="left">{item?.courseModelName}</CustomTableCell>
                  <CustomTableCell align="left">
                    <CustomDate date={item.createdAt} />
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    <CustomMenuWrapper
                      menuButton={<img src={iconmore} className="cursor-pointer" />}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      hasMultiple={true}
                    >
                      <div style={{ width: 100 }}>
                        <MenuItem onClick={() => openView(item)} component={"div"}>
                          <Typography variant="button">View</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => openEdit(item)} component={"div"}>
                          <Typography variant="button">Edit</Typography>
                        </MenuItem>
                        {item.isDeleted === false && (
                          <MenuItem onClick={() => deleteCourseModel(item)} component={"div"}>
                            <Typography variant="button">Delete</Typography>
                          </MenuItem>
                        )}
                      </div>
                    </CustomMenuWrapper>
                  </CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <div className="w-100">
          <VigilearnPaginationApi<ICourseModelResponse>
            itemsCount={pager.totalItems}
            handleChangePageParent={handleChangePageParent}
            items={pageOfItems}
            searchData={coursemodelsearchData}
            updateSearch={updateSearch}
            listMetaData={listMetaData}
            refresh={refresh}
            service={getAllCourseModelsApiService}
          />
        </div>
      </PageLayout>
      <AddCourseModel openDialog={openDialog} handleClose={handleClose} />
      {item && <EditCourseModel item={item} editDialog={editDialog} handleClose={handleCloseEdit} />}
      {item && <ViewCourseModel item={item} viewDialog={viewDialog} handleClose={handleCloseView} />}
    </Aux>
  );
};

export default CourseModel;
