import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";


const customIconStyles = makeStyles((theme: Theme) =>
    createStyles({
        root:{
            fontSize:'14px',
            display:'flex',
            justifyContent:'center'
        }
    }),
);
export default customIconStyles