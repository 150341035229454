import { Collapse, Divider, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React from "react";
import { matchPath } from "react-router-dom";
import { navigation, navigationChildren } from "types/navigation";
import customMenuItemStyle from "./customMenuItemStyle";

export interface CustomMenuItemProps {
  item: navigation;
  onitemClick?: (item: navigation) => void;
  onSubItemClick?: (item: navigationChildren) => void;
  isselected?: boolean | any;
  open?: boolean;
  selectedId?: string;
}

const CustomMenuItem: React.FC<CustomMenuItemProps> = ({
  selectedId,
  onSubItemClick,
  isselected,
  item,
  onitemClick,
  open,
}) => {
  const classes = customMenuItemStyle();
  const getImage = (imageId: string) => {
    return <img width={18} alt={imageId} src={require(`assets/svg/sidebaricons/${imageId.toLowerCase()}.svg`) ?? ""} />;
  };

  const MenuItemChildren = item.children ? (
    <Collapse in={open && item.id === selectedId} timeout="auto" unmountOnExit>
      <Divider />
      {item.children.map((childItem: navigationChildren, index: number) => (
        <div className={classes.topLevel} key={index}>
          <label className={matchPath(window.location.pathname, childItem.url) ? "leftLine " : "leftLineHide"}></label>
          <div className={classes.parentDiv}>
            <div
              className={clsx(
                classes.sideItem,
                matchPath(window.location.pathname, childItem.url) && classes.sideItemSelected,
              )}
            >
              <ListItem
                className={clsx(
                  classes.rootChild,
                  matchPath(window.location.pathname, childItem.url) ? classes.selected : classes.notselected,
                )}
                button
                onClick={() => onSubItemClick?.(childItem)}
              >
                <ListItemText primary={childItem.title} />
              </ListItem>
            </div>
          </div>
        </div>
      ))}
    </Collapse>
  ) : null;
  return (
    <>
      <div className={classes.topLevel}>
        <label className={isselected ? "leftLine " : "leftLineHide"}></label>
        <div className={classes.parentDiv}>
          <div className={clsx(classes.sideItem, isselected && classes.sideItemSelected)}>
            <ListItem
              className={clsx(classes.root, isselected ? classes.selected : classes.notselected)}
              button
              onClick={() => onitemClick?.(item)}
              key={item.url}
            >
              <ListItemIcon classes={{ root: classes.iconRoot }}>{getImage(item.id)}</ListItemIcon>

              <ListItemText primary={item.title} />
              {item.children && !open && <IconExpandMore htmlColor="white" />}
              {item.children && open && <IconExpandLess htmlColor="white" />}
            </ListItem>
          </div>
        </div>
      </div>
      {MenuItemChildren}
    </>
  );
};
export default CustomMenuItem;
