import { useAuth0 } from "@auth0/auth0-react";
import { Fade, Grid, NoSsr, Typography } from "@material-ui/core";
import { AxiosError, AxiosResponse } from "axios";
import OnboardingRightContainer from "components/shared/Auth/OnboardingRightContainer";
import Aux from "components/shared/_Aux";
import CustomButton from "components/ui/CustomButton/CustomButton";
import CustomInput from "components/ui/CustomInput/CustomInput";
import { Space } from "components/ui/Space/Space";
import useForm from "hooks/useForm";
import useHandleError from "hooks/useHandleError";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { verifyAccountApiService } from "services/user";
import authStyles from "../../styles/authStyles";
import { INormalResponse } from "types/response";
import { IUser, IVerifyAccountRequest } from "types/user";
import { verifyAccountErrors } from "utils/validationStates";
import { validatorAll } from "utils/validatorFunctions";
/**
 * @description Setup Applicant Account after signup
 */
const Activate = () => {
  const classes = authStyles();

  const initFormState = {
    password: "",
    confirmPassword: "",
  };
  const { handleAppError } = useHandleError();
  const { addToast } = useToasts();
  const [togglePassword, settogglePassword] = useState(false);
  const [togglePasswordTwo, settogglePasswordTwo] = useState(false);
  // const { loginWithRedirect } = useAuth0();
  const { loginWithRedirect } = useAuth0<Omit<IUser, "gender">>();

  const { handleCreateFormInputChange, inputs, setInputs, err, hasError, setError } = useForm<IVerifyAccountRequest>(
    initFormState,
    true,
    verifyAccountErrors,
    "Activate",
  );
  const [creating, setcreating] = useState(false);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const userId = new URLSearchParams(search).get("userId");

  useEffect(() => {
    if (token && userId) {
      console.log(token, userId);

      setInputs((prevState) => ({
        ...prevState,
        userId: userId as string,
        activationCode: token as string,
      }));
    }
  }, [token, userId]);

  console.log("inputss", inputs);
  const handleTogglePassword = () => {
    settogglePassword(!togglePassword);
  };
  const handleTogglePasswordTwo = () => {
    settogglePasswordTwo(!togglePasswordTwo);
  };
  const handleProceed = () => {
    setcreating(true);
    validatorAll(
      [
        { name: "password", value: inputs.password, label: "Password" },
        {
          name: "confirmPassword",
          value: inputs.confirmPassword,
          password: inputs.password,
          label: "Password",
        },
      ],
      "Activate",
      setError,
      err,
      hasError,
    );
    if (!hasError.current) {
      setcreating(false);
      return;
    }
    if (Object.keys(hasError?.current).length > 0) {
      setcreating(false);
      return;
    }

    verifyAccountApiService(inputs)
      .then((res: AxiosResponse<INormalResponse>) => {
        setcreating(false);
        if (res.data.hasErrors) {
          addToast(res.data?.description ?? "An error occurred, please try again.", {
            appearance: "error",
          });
        } else {
          addToast("Registration Completed", {
            appearance: "success",
          });
          setTimeout(() => {
            loginWithRedirect({
              screen_hint: "login",
              redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URL,
            });
          }, 500);
        }
      })
      .catch((axiosError: AxiosError) => {
        handleAppError(axiosError, err);
        setcreating(false);
      });
  };
  return (
    <Aux>
      <Grid container className={classes.container}>
        <OnboardingRightContainer />
        <div className={classes.formContainer}>
          <div className={classes.signinform}>
            <Aux>
              <Fade in>
                <Typography variant="h4">
                  <b>Complete Registration</b>
                </Typography>
              </Fade>
              <Space bottom={10} />
              <Typography variant="caption">Fill the form to complete your registration.</Typography>
              <Space bottom={20} />
              <Space top={20} />
              <NoSsr>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <CustomInput
                      name="password"
                      errorText={err.password}
                      showError={err.password.length > 0}
                      value={inputs.password}
                      handleChange={(e) => handleCreateFormInputChange("password", "Password", e)}
                      labelText="Password"
                      id="login-password"
                      placeholder="Password"
                      // showDefaultBorder={false}
                      type={togglePassword ? "text" : "password"}
                      endIcon={
                        <div className="cursor-pointer" onClick={handleTogglePassword}>
                          {togglePassword ? "Hide" : "Show"}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CustomInput
                      errorText={err.confirmPassword}
                      showError={err.confirmPassword.length > 0}
                      value={inputs.confirmPassword}
                      handleChange={(e) =>
                        handleCreateFormInputChange("confirmPassword", "Confirm Password", e, true, inputs.password)
                      }
                      name="confirmPassword"
                      labelText="Confirm Password"
                      id="login-confirmPassword"
                      placeholder="Confirm Password"
                      // showDefaultBorder={false}
                      type={togglePasswordTwo ? "text" : "password"}
                      endIcon={
                        <div className="cursor-pointer" onClick={handleTogglePasswordTwo}>
                          {togglePasswordTwo ? "Hide" : "Show"}
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </NoSsr>
              <Space top={20} />

              <Space top={20} />
              <Space bottom={20} />
              <div className={classes.formButtons}>
                <CustomButton
                  loading={creating}
                  disabled={creating}
                  onClick={handleProceed}
                  id="activateButton"
                  text="Sign Up"
                  fontSize={16}
                />
              </div>

              {/* <div className={classes.noAccount}>
                <p>
                  Already an account?
                  <Link href="/login" passHref>
                    <span> Login</span>
                  </Link>
                </p>
              </div> */}
            </Aux>
          </div>
        </div>
      </Grid>
    </Aux>
  );
};

export default Activate;
