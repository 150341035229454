import { makeStyles } from "@material-ui/core";

const customActionButtonStyle = makeStyles(theme => ({
    root: {
        minWidth:'100%',
        color: '#18191F80',
        fontWeight: 'bold',
        boxShadow: 'none',
        textTransform: 'none',
        borderRadius: 4,
        height: 40,
        backgroundColor: theme.palette.primary.main,
        

        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
    },
    border:{
        border: `2px solid ${theme.palette.grey[100]}`,
    },
    actionButtonText: {
        fontWeight: 'bold',
        fontSize: 13,
    },
    label: {
    }
}))

export default customActionButtonStyle;