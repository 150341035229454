import { Button, ButtonProps, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import customActionButtonStyle from './customActionButtonStyle';

interface CustomActionButtonProps {
  props?: ButtonProps;
  text?: string | JSX.Element;
  startIcon?: any;
  backgroundColor?: string;
  width?: number;
  border?: boolean;
  textColor?: string;
  className?: string;
  onClick?: () => void;
  loading?: boolean;
  borderColor?: string;
  show?: boolean;
}

const CustomActionButton = ({
  startIcon,
  show = true,
  border,
  loading,
  backgroundColor,
  borderColor,
  width,
  textColor,
  text,
  onClick
}: CustomActionButtonProps) => {
  const classes = customActionButtonStyle();
  const theme = useTheme();
  return (
    show ? (
      <>
        <Button
          startIcon={startIcon}
          className={clsx(classes.root, border && classes.border, classes.label)}
          classes={{
            text: classes.actionButtonText
          }}
          style={{
            backgroundColor: backgroundColor,
            border: `1px solid ${borderColor}`,
            // props.backgroundColor || theme.palette.primary.main,
            // ...props,
            width: width,
            color: textColor
          }}
          onClick={onClick}
          disableElevation
          // {...props}
        >
          {text}
        </Button>
      </>
      
    ):<></>
  );
};

export default CustomActionButton;
