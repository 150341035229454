import { AxiosError, AxiosResponse } from "axios";
import CustomInput from "components/ui/CustomInput/CustomInput";
import CustomModal from "components/ui/Modal/CustomModal";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import useForm from "../../hooks/useForm";
import useHandleError from "../../hooks/useHandleError";
import { addCourseModelApiService } from "../../services/coursemodel";
import { IAddCourseModel } from "../../types/coursemodel";
import { INormalResponse } from "../../types/response";
import { createcourseModelErrors } from "../../utils/validationStates";
import { validatorAll } from "../../utils/validatorFunctions";

export interface AddCourseModelProps {
  openDialog: boolean;
  handleClose: () => void;
}

const AddCourseModel = ({ openDialog, handleClose }: AddCourseModelProps) => {
  const initFormState: IAddCourseModel = {
    courseModelName:"",
  };
  const { addToast } = useToasts();

  const [creating, setcreating] = useState(false);
  const {handleAppError} = useHandleError()

  const { handleCreateFormInputChange, hasError, inputs, err, clearForm, setError } = useForm<IAddCourseModel>(
    initFormState,
    true,
    createcourseModelErrors,
    "AddCourseModel",
  );
  const handleConfirm = () => {

    setcreating(true);
    validatorAll(
      [
        { name: "courseModelName", value: inputs.courseModelName, label: "Name" },
      ],
      "AddCourseModel",
      setError,
      err,
      hasError,
    );
    if (!hasError.current) {
      setcreating(false);
      return;
    }
    if (Object.keys(hasError?.current).length > 0) {
      setcreating(false);

      return;
    }

    addCourseModelApiService(inputs)
    .then((res: AxiosResponse<INormalResponse>) => {
      setcreating(false);
      if(res.data.hasErrors){
        addToast(res.data.description, {
          appearance: "error",
        });
        clearForm?.()
      }else{
        addToast("Course Model Created", {
          appearance: "success",
        });
      }
        handleClose?.();
      })
      .catch((axiosError: AxiosError) => {
        handleAppError(axiosError, err);
        setcreating(false);
      });
  };
  const handleToggleAddDialog = () => {
    clearForm?.()
    handleClose?.();
  }
  return (
    <CustomModal
      open={openDialog}
      handleCancel={handleToggleAddDialog}
      handleClose={handleToggleAddDialog}
      title="Add Course Delivery Model"
      showCancel
      showConfirm
      confirmText="Add"
      showDividers={true}
      confirmLoading={creating}
      confirmDisabled={creating}
      handleConfirm={handleConfirm}
      content={
        <>
          <div>
            <div className="mt-4">
            <CustomInput
            errorText={err.courseModelName}
            showError={err.courseModelName.length > 0}
            value={inputs?.courseModelName}
            handleChange={(e) => handleCreateFormInputChange?.("courseModelName", "Name", e)}
            
                name="programName"
                labelText="Course Model Name"
                id="programName"
                placeholder="Course Model Name"
              />
            </div>
            
          </div>
        </>
      }
    />
  );
};
export default AddCourseModel;
