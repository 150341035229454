import axios, { AxiosError } from 'axios';
import { getBaseUrl } from 'utils/globalUtils';
import { getDefaultAuth } from '../context/AuthContext';

const AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
});
AxiosInstance.interceptors.request.use(
  (config: any) => {
    const token = getDefaultAuth()
    if (token) config.headers['Authorization'] = token;
    config.headers['Content-Type'] = 'application/json';
    return config;
  },

  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default AxiosInstance;
