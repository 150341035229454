import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { linkColor } from "../../theme/default";

const authLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainBrandText: {
      color: theme.palette.primary.main,
      fontSize: 18,
      marginTop: 23,
      marginLeft: 5,
    },
    mainBrand404: {
      marginLeft: 68,
      marginTop: -37,
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(2),
      },
    },
    proceedText: {
      "& label": {
        color: linkColor,
        cursor: "pointer",
        fontSize: 14,
        fontWeight: 400,
        margin: 0,
      },
    },
  }),
);
export default authLayoutStyles;
