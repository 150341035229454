// import vigilearn from "assets/svg/mainbrand.svg";
import edutech from "assets/images/edutech-logo.png";
import { Link } from "react-router-dom";
import React from "react";
import authStyles from "../../../styles/authStyles";

const OnboardingRightContainer: React.FC = () => {
  const classes = authStyles();
  return (
    <div className={classes.logotypeContainer}>
      <Link to={"/"}>
        <div className={classes.loginLogoContainer}>
          <div>
            <img src={edutech} className="cursor-pointer" alt="Edutech" />
          </div>
        </div>
      </Link>
      <div className={classes.loginwriteupcontainer}></div>
    </div>
  );
};

export default OnboardingRightContainer;
