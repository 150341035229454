import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { ITheme } from "../../theme";
const paginationStyles = makeStyles((theme: ITheme) =>
  createStyles({
    rootPagination: {
      display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          paddingLeft:15,
          paddingRight: 15,
          paddingTop:20,
          paddingBottom:10
    },
    paginationRoot: {
      fontSize: 15,
      "& .previous": {
        color: theme.palette.variants.primaryBlack,
        fontWeight: "normal",
        marginRight: 8,
        cursor: "pointer",
      },
      "& .next": {
        color: theme.palette.variants.primaryBlack,
        fontWeight: "normal",
        marginLeft: 8,
        cursor: "pointer",
      },
      "& .page": {
        marginLeft: 8,
        marginRight: 8,
        cursor: "pointer",
      },
      "& .page-selected": {
        backgroundColor: theme.palette.variants.primaryVariantOne,
        color: theme.palette.variants.deepBlueFive,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 3,
        paddingBottom: 3,
        fontWeight: 400,
        cursor: "pointer",
      },
    },
  }),
);
export default paginationStyles;
