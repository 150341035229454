import { Drawer, Hidden, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import edutech from "assets/images/EduTech_Logo_WHITE.svg";
// import vigilearn from "assets/svg/mainbrand.svg";
import CustomMenuItem from "components/shared/CustomMenuItem/CustomMenuItem";
import { Space } from "components/ui/Space/Space";
import { uniqueId } from "lodash";
import React, { useState } from "react";
import { Link, matchPath, useHistory } from "react-router-dom";
import sidenavStyles from "styles/components/sidenavStyles";
import { navigation, navigationChildren } from "types/navigation";
interface SideNavProps {
  window?: () => Window;
  mobileOpen?: boolean;
  handleDrawerToggle?: () => void;
  menuItems: navigation[] | undefined;
}
const SideNav = (props: SideNavProps) => {
  const { window, mobileOpen, handleDrawerToggle, menuItems } = props;
  const theme = useTheme();
  const history = useHistory();
  const container = window !== undefined ? () => window().document.body : undefined;
  const classes = sidenavStyles();
  const matchesMobile = useMediaQuery((appTheme: Theme) => appTheme.breakpoints.down("md"));
  const [open, setopen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const onitemClick = (currentItem: navigation) => {
    setselectedId(currentItem.id);
    if (currentItem.children) {
      if (currentItem.id === selectedId) {
        setopen(!open);
      } else if (currentItem.id !== selectedId) {
        if (open) {
          setopen(false);
          setopen(true);
        } else {
          setopen(!open);
        }
      }

      return;
    }

    if (matchesMobile) {
      handleDrawerToggle?.();
    }

    history.push(currentItem.url as unknown as string);
  };
  const onSubItemClick = (currentItem: navigationChildren) => {
    history.push(currentItem.url as unknown as string);
  };
  const navItems = menuItems
    ?.filter((item) => item?.show !== false)
    ?.map((item) => {
      // matchPath(location.pathname, item.url);
      // eslint-disable-next-line no-restricted-globals
      const isSelected = matchPath(location.pathname, item.url);
      return (
        <div key={uniqueId(item.id)} className={classes.groupMenu}>
          <div>
            <CustomMenuItem
              isselected={isSelected}
              onSubItemClick={onSubItemClick}
              open={open}
              key={item.id}
              selectedId={selectedId}
              onitemClick={onitemClick}
              item={item}
            />
          </div>
        </div>
      );
    });

  const drawer = (
    <div>
      <Link to="/app/home">
        <div className={classes.logoContainer}>
          <div>
            <img src={edutech} alt="Edutech" />
          </div>
        </div>
      </Link>
      <div className={classes.toolbar} />
      <Space bottom={20} top={20} />
      {navItems}
    </div>
  );
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};
export default SideNav;
