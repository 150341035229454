import React, { createContext, FC, ReactChild, useState } from "react";
import { ISchoolSettingsRequest, ISettings } from "../types/settings";
const initState = {
  editSettingsData: {} as ISettings & ISchoolSettingsRequest,
 };
type initState = {
  editSettingsData?: ISettings & ISchoolSettingsRequest;
  saveEditSettingsData: (v: ISettings & ISchoolSettingsRequest) => void;
};
const SettingsContext = createContext<initState>({} as unknown as initState);
export const SettingsProvider = SettingsContext.Provider;


interface Props {
  children?: ReactChild | ReactChild[];
}

export const SettingsProviderContainer: FC<Props> = ({ children }) => {
  const [editSettingsData, seteditSettingsData] = useState<ISettings & ISchoolSettingsRequest>(initState.editSettingsData);
 
  const saveEditSettingsData = (editSettingsData: ISettings & ISchoolSettingsRequest) => {
    if(editSettingsData){
      seteditSettingsData(editSettingsData)
    }
  };


  return (
    <SettingsProvider
      value={{
        editSettingsData,
        saveEditSettingsData,
         }}
    >
      {children}
    </SettingsProvider>
  );
};
export default SettingsContext;
