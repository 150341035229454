import React from "react";
import { IRoute } from "../types/Gen";
import RootSettings from "pages/Settings/RootSettings";

/**
 * Dashboard
 */
const Dashboard = React.lazy(() => import("pages/Dashboard/Dashboard"));
/**
 * Courses
 */
const Courses = React.lazy(() => import("pages/Courses/RootCourses"));
/**
 * AddCourse
 */
const AddCourse = React.lazy(() => import("pages/Courses/AddCourse"));
/**
 * ViewCourse
 */
const ViewCourse = React.lazy(() => import("pages/Courses/ViewCourse"));
/**
 * EditCourse
 */
const EditCourse = React.lazy(() => import("pages/Courses/EditCourse"));

/**
 * Institutions
 */
const Institutions = React.lazy(() => import("pages/Institutions/RootInstitution"));
/**
 * Products
 */
const Products = React.lazy(() => import("pages/Products/Products"));
/**
 * Categories
 */
const Categories = React.lazy(() => import("pages/Categories/Categories"));
/**
 * Scholarships
 */
const Scholarships = React.lazy(() => import("pages/Scholarship/Scholarship"));
/**
 * AuditTrail
 */
const AuditTrail = React.lazy(() => import("pages/AuditTrail/AuditTrail"));
/**
 * Students
 */
const Students = React.lazy(() => import("pages/Students/Students"));
/**
 * Signups
 */
const Signups = React.lazy(() => import("pages/Signups/Signups"));
/**
 * Testimonial
 */
const Testimonial = React.lazy(() => import("pages/Testimonial/Testimonial"));
/**
 * Instructors
 */
const Instructors = React.lazy(() => import("pages/Instructor/Instructors"));
/**
 * Curriculum
 */
const Curriculum = React.lazy(() => import("pages/Curriculum/Curriculum"));
/**
 * AdminUsers
 */
const AdminUsers = React.lazy(() => import("pages/UserManagement/AdminUsers/AdminUsers"));

/**
 * RolesPermissions
 */
const RolesPermissions = React.lazy(() => import("pages/UserManagement/RolesPermissions/RolesPermissions"));

/**
 * Profile
 */
const Profile = React.lazy(() => import("pages/Account/Profile"));

/**
 * Settings
 */
const Settings = React.lazy(() => import("pages/Settings/Settings"));

/**
 * CourseProgram
 */
const CourseProgram = React.lazy(() => import("pages/Settings/CourseProgram"));

/**
 * CourseProgram
 */
const CourseType = React.lazy(() => import("pages/CourseType/CourseType"));

/**
 * MethodOfStudy
 */
const MethodOfStudy = React.lazy(() => import("pages/MethodOfStudy/MethodOfStudy"));

/**
 * Cohort
 */
const Cohort = React.lazy(() => import("pages/Cohorts/Cohort"));

/**
 * Country
 */
const Country = React.lazy(() => import("pages/Country/Country"));

/**
 * Degree
 */
const Degree = React.lazy(() => import("pages/Degree/Degree"));

/**
 * Duration
 */
const Duration = React.lazy(() => import("pages/Duration/Duration"));

/**
 * Settings
 */
const Specialization = React.lazy(() => import("pages/Specialization/Specialization"));

/**
 * Add Settings
 */
const AddSettings = React.lazy(() => import("pages/Settings/AddSettings"));

/**
 * Edit Settings
 */
const EditSettings = React.lazy(() => import("pages/Settings/EditSettings"));

const routes: IRoute[] = [
  {
    path: "/app/home",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/app/courses",
    exact: true,
    name: "Courses",
    component: Courses,
  },
  {
    path: "/app/courses/create",
    exact: true,
    name: "CoursesCreate",
    component: AddCourse,
  },
  {
    path: "/app/courses/:id",
    exact: true,
    name: "CoursesView",
    component: ViewCourse,
  },
  {
    path: "/app/courses/edit/:id",
    exact: true,
    name: "CoursesEdit",
    component: EditCourse,
  },
  {
    path: "/app/institutions",
    exact: true,
    name: "Institutions",
    component: Institutions,
  },
  {
    path: "/app/products",
    exact: true,
    name: "Products",
    component: Products,
  },
  {
    path: "/app/categories",
    exact: true,
    name: "Categories",
    component: Categories,
  },
  {
    path: "/app/scholarships",
    exact: true,
    name: "Scholarships",
    component: Scholarships,
  },
  {
    path: "/app/audittrail",
    exact: true,
    name: "AuditTrail",
    component: AuditTrail,
  },
  {
    path: "/app/applicants",
    exact: true,
    name: "Students",
    component: Students,
  },
  {
    path: "/app/signups",
    exact: true,
    name: "Signups",
    component: Signups,
  },
  {
    path: "/app/testimonials",
    exact: true,
    name: "Testimonial",
    component: Testimonial,
  },
  {
    path: "/app/instructors",
    exact: true,
    name: "Instructors",
    component: Instructors,
  },
  {
    path: "/app/curriculum",
    exact: true,
    name: "Curriculum",
    component: Curriculum,
  },
  {
    path: "/app/rolespermissions",
    exact: true,
    name: "RolesPermissions",
    component: RolesPermissions,
  },
  {
    path: "/app/adminusers",
    exact: true,
    name: "AdminUsers",
    component: AdminUsers,
  },
  {
    path: "/app/profile",
    exact: true,
    name: "Profile",
    component: Profile,
  },
  {
    path: "/app/CourseProgram",
    exact: true,
    name: "CourseProgram",
    component: CourseProgram,
  },
  {
    path: "/app/coursetype",
    exact: true,
    name: "CourseType",
    component: CourseType,
  },
  {
    path: "/app/methodofstudy",
    exact: true,
    name: "MethodOfStudy",
    component: MethodOfStudy,
  },
  {
    path: "/app/cohort",
    exact: true,
    name: "MethodOfStudy",
    component: Cohort,
  },
  {
    path: "/app/country",
    exact: true,
    name: "Country",
    component: Country,
  },
  {
    path: "/app/degree",
    exact: true,
    name: "Degree",
    component: Degree,
  },
  {
    path: "/app/duration",
    exact: true,
    name: "Duration",
    component: Duration,
  },
  {
    path: "/app/specialization",
    exact: true,
    name: "Specialization",
    component: Specialization,
  },
  {
    path: "/app/settings",
    exact: true,
    name: "Settings",
    component: RootSettings,
  },
  // {
  //   path: "/app/settings",
  //   exact: true,
  //   name: "Settings",
  //   component: Settings,
  // },
  {
    path: "/app/settings/create",
    exact: true,
    name: "SchoolSettings",
    component: AddSettings,
  },
  {
    path: "/app/settings/edit/:id",
    exact: true,
    name: "EditSettings",
    component: EditSettings,
  },
];

export default routes;
