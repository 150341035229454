import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import mainbrand from "assets/svg/mainbrand.svg";
import { AxiosError, AxiosResponse } from "axios";
import CustomButton from "components/ui/CustomButton/CustomButton";
import CustomInput from "components/ui/CustomInput/CustomInput";
import { Space } from "components/ui/Space/Space";
import useForm from "hooks/useForm";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import authLayoutStyles from "styles/components/authLayoutStyles";
import loginStyles from "styles/loginStyles";
import { forgotPasswordErrors } from "utils/validationStates";
import AuthContext from "../../context/AuthContext";
import { forgotPasswordApiService } from "../../services/user";
import { IBackendResponse, IResponse } from "../../types/response";
import { IForgotPasswordRequest } from "../../types/user";
import { handleError } from "../../utils/errorHandler";
import { validatorAll } from "../../utils/validatorFunctions";
export const ForgotPassword = () => {
  const { addToast } = useToasts();

  const classes = loginStyles();
  const history = useHistory();
  const [togglePassword, settogglePassword] = useState(false);
  const { setUserCredentials, setAuthAndCache, updateCurrentUser } = useContext(AuthContext);

  const initFormState = {
    email: "",
  };
  const { handleCreateFormInputChange, clearForm, inputs, err, hasError, setError } = useForm<IForgotPasswordRequest>(
    initFormState,
    true,
    forgotPasswordErrors,
    "ForgotPassword",
  );
  const [loading, setLoading] = useState(false);
  const AuthLayoutClasses = authLayoutStyles();
  const handleLogin = () => {
    setLoading(true);
    validatorAll([{ name: "email", value: inputs.email, label: "Email" }], "ForgotPassword", setError, err, hasError);
    if (!hasError.current) {
      setLoading(false);
      return;
    }
    if (Object.keys(hasError?.current).length > 0) {
      setLoading(false);
      return;
    }
    forgotPasswordApiService(inputs)
      .then((res: AxiosResponse<IResponse<IBackendResponse>>) => {
        if (res.data.hasErrors) {
          addToast(res.data.description, {
            appearance: "error",
          });
        } else {
          addToast(res.data?.description ?? "Success", {
            appearance: "success",
          });
        }
        clearForm?.();
        setLoading(false);
      })
      .catch((axiosError: AxiosError) => {
        const { error } = handleError(axiosError, err);
        console.log("error", error);
        if (typeof error === "string") {
          addToast(error, {
            appearance: "error",
          });
        } else {
          addToast("Server Error, Please try again.", {
            appearance: "error",
          });
        }
        setLoading(false);
      });
  };

  return (
    <Container component="main">
      <div className={classes.paper}>
        <div className={AuthLayoutClasses.mainBrand404.concat(" ms-0")}>
          <Grid container>
            <Grid item md={12} className="text-center">
              <div>
                <img src={mainbrand} alt="mainBrand" />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.formRoot}>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Typography className="proceed">Enter your email to reset proceed</Typography>
          <Space top={5} />
          <div className={classes.form}>
            <CustomInput
              errorText={err.email}
              showError={err.email.length > 0}
              value={inputs.email}
              name="email"
              handleChange={(e) => handleCreateFormInputChange("email", "Email", e)}
              type="text"
              labelText="Email Address"
              useGreyBackground
              id="login-email"
              placeholder="Enter Email Address"
            />

            <Grid container className="mt-4">
              <Grid item md={12} xs={12} className="text-center">
                <CustomButton
                  borderRadius={4}
                  loading={loading}
                  disabled={loading}
                  onClick={handleLogin}
                  text="Recover Password"
                />
              </Grid>
            </Grid>
            <Space top={20} />
            <Grid container className="">
              <Grid item md={12} className="text-center">
                <Link to="/login" className="forgot-password">
                  Login
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid container>
          <Grid item md={12} xs={12} className="text-center mt-3">
            <Typography>&copy; {new Date().getFullYear()} Edutech - All Rights Reserved.</Typography>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};
