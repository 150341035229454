import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IPager } from "types/Pagination";
import PageLayout from "../../components/hoc/PageLayout";
import TopNavigation from "../../components/ui/Tab/TopNavigation";
import settingsStyles from "../../styles/settingsStyles";
import CourseModel from "./CourseModel";
import CourseProgram from "./CourseProgram";
import Settings from "./Settings";

export const RootSettings: React.FC<{}> = ({}) => {
  const classes = settingsStyles();
  const [totalItems, setTotalItems] = useState(0);
  const [tabIndex, settabIndex] = useState(0);

  const updateId = (index: number) => {
    const realIndex = index;
    const settingsNavigationKey = tabIndex;
    if (window.location.pathname.includes("settings") && settingsNavigationKey && settingsNavigationKey === 0) {
      settabIndex(0);
    } else {
      if (realIndex >= 0) {
        settabIndex(realIndex);
      }
      settabIndex(realIndex);
    }
  };

  // const handleSaveDetails = () => {
  //   setsaving(true);
  // };

  const returnData = (pager: IPager) => {
    setTotalItems(pager.totalItems);
    return;
  };

  const handlesetTabIndex = (index: number) => {
    settabIndex(index);
  };

  return (
    <PageLayout pageTitle={"Settings"}>
      <div className={classes.overviewTabs}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <TopNavigation
              id={tabIndex.toString()}
              updateId={updateId}
              tabLeft={25}
              handlesetTabIndex={handlesetTabIndex}
              tabs={[
                {
                  label:
                    tabIndex === 0 ? `${"Institution Settings" + " (" + totalItems + ")"}` : "Institution Settings",
                  index: 0,
                  hidden: false,
                },
                // {
                //   label: tabIndex === 1 ? `${"Course Type" + " (" + totalItems + ")"}` : "Course Type",
                //   index: 1,
                //   hidden: false,
                // },
                {
                  label: tabIndex === 1 ? `${"Course Model" + " (" + totalItems + ")"}` : "Course Model",
                  index: 1,
                  hidden: false,
                },
              ]}
              tabPanels={[
                {
                  index: 0,
                  component: <Settings returnData={returnData} />,
                },
                // {
                //   index: 1,
                //   component: <CourseProgram returnData={returnData} />,
                // },
                {
                  index: 1,
                  component: <CourseModel returnData={returnData} />,
                },
              ]}
              tabIndex={tabIndex}
            />
          </Grid>
        </Grid>
      </div>
    </PageLayout>
  );
};

export default RootSettings;
