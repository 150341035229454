import tinycolor from "tinycolor2";

//Color Profiles
const VLBlue = {
  light: "#4272cf",
  main: "#1354D3",
  dark: "#0E347E",
  contastText: "#FFFFFF",
};
const VLRed = {
  light: "#F0AB9A",
  main: "#E43535",
  contrastText: "#FFFFFF",
};
const VLWhite = {
  light: "#FFF",
  main: "#FFFFFF",
  dark: "#FCFBFB",
  contrastText: "#1354D3",
};

const VLGrey = {
  main: "#F4F5F6",
  light: "#D7DCE0",
  dark: "#BAC2C9",
  contastText: "#0D0F11",
};
const VLLight = {
  main: "#DFE1E6",
  dark: "#3A434B",
  light: "#81909D",
  contrastText: "#0D0F11",
};
export const primary = "#18191F";
const success = "#69D895";
export const linkColor = "#38369A";
export const checkBoxColor = "#508AA8";
export const lightenRate = 7.5;
const darkenRate = 15;
export const lightPurple = "#F8F4FD";
export const lightGrey = "#FAFAFA";
export const white = "#ffffff";
export default {
  palette: {
    primary: {
      light: "#4272cf",
      main: "#1354D3",
      dark: "#0E347E",
      contastText: "#FFFFFF",
    },
    secondary: VLWhite,
    warning: VLRed,
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: VLGrey,
    text: {
      primary: VLLight.dark,
      secondary: VLLight.light,
      disabled: VLGrey.main,
    },
    variants: {
      grey: VLGrey,
      primaryBlack: "#3A434B",
      primaryVariantOne: "#E8EFFD",
      primaryVariantTwo: "#5E4BCE",
      primvarVariantThree: "#677684",
      primvarVariantFour: "#261B64",
      primvaryVariantFive: "#0F42A4",
      tableHeader: "#0D0F11",
      deepBlueThree: "#0E347E",
      deepBlueFive: "#1354D3",
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          textDecoration: "none !important",
          color: primary,
          "&:hover": {
            color: tinycolor(primary).lighten(50),
          },
        },
      },
    },
    typography: {
      fontSize: 13,
      h1: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 700,
        fontSize: "7.5rem",
      },
      h2: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 700,
        fontSize: "5rem",
      },
      h3: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 600,
        fontSize: "1.5rem",
      },
      h4: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 500,
        fontSize: "1.5rem",
      },
      h5: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 600,
        fontSize: "1.125rem",
      },
      subtitle1: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: "0.875rem",
      },
      subtitle2: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 600,
        fontSize: "0.875rem",
      },
      body1: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: "0.875rem",
      },
      body2: {
        fontFamily: "Inter, sans-serif",
        fontWeight: 600,
        fontSize: "0.875rem",
      },
      "@media (min-width:1680px)": {
        fontSize: 15,
      },
      caption: {
        color: "#A6A6A6",
        fontSize: "14px",
      },

      fontFamily: ["-apple-system", "BlinkMacSystemFont", "Inter", "Roboto"].join(","),
    },
    MuiLink: {
      root: {
        color: linkColor,
        textDecoration: "none",
        "&:hover": {
          color: linkColor,
          opacity: 0.8,
          textDecoration: "none !important",
        },
      },
    },
    MuiCheckbox: {
      root: {
        // color: checkBoxColor,
        // '&$checked': {
        //   color: `${checkBoxColor} !important`,
        // },
        color: "#E0E3E5",
        "&$checked": {
          color: `${checkBoxColor} !important`,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        color: "rgba(24, 25, 31, 0.5)",
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: "34px !important",
        paddingTop: "10spx",
        paddingBottom: "10px",
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: white,
        "&:hover": {
          backgroundColor: `${lightPurple} !important`,
        },
      },
    },
    MuiTablePagination: {
      selectRoot: {
        "@media only screen and (max-width:600px)": {
          marginRight: "auto",
        },
      },
      toolbar: {
        marginLeft: "-25px",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#D1D1D2",
      },
    },
  },
};
