import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

const noRecordStyles = makeStyles(() =>
  createStyles({
    noRecordRoot: {
      margin: "auto",
      "& img": {
        marginBottom: 100,
      },
    },
  }),
);
export default noRecordStyles;
