import { makeStyles } from "@material-ui/core";
import { drawerWidth } from "../../../constants/appConstants";
import { ITheme } from "../../../theme";

const customMenuItemStyle = makeStyles((theme: ITheme) => ({
  iconRoot: {
    minWidth: "30px !important",
  },
  subItem: {
    paddingLeft: 45,
  },
  parentDiv: {
    width: drawerWidth-5,
    textTransform: "capitalize",
    "&:hover": {},
    "& span": {},
  },
  sidelineIndicator: {
    backgroundColor: "#2e1963",
    left: 0,
    width: "4px",
    height: "40%",
    opacity: 0,
    transformOrigin: "center center",
    transition: "all .3s ease-out",
    // borderRadius: "0 4px 4px 0",
  },
  topLevel: {
    display: "inline-flex",
    cursor: "pointer",
    position: "relative",
    "& .leftLineHide": {
      borderRight: `4px solid ${theme.palette.primary.main}`,

      marginTop: 0,
      opacity: 0,
      transformOrigin: "center center",
      transition: "all .3s ease-out",
      "&:hover": {},
    },
    "& .leftLine": {
      borderRight: `3px solid ${theme.palette.primary.contrastText}`,
      opacity: 1,
      transformOrigin: "center center",
      transition: "all .3s ease-out",
      "&:hover": {},
    },
    "&:hover": {
      position: "relative",
      "& .leftLineHide": {
        borderRight: `4px solid ${theme.palette.success.main}`,
        height: 40,
        opacity: 1,
        transformOrigin: "center center",
        transition: "all .3s ease-out",
        "&:hover": {
          position: "relative",
        },
      },
      "& .leftLine": {
        borderRight: `4px solid ${theme.palette.primary.contrastText}`,
        // height: 40,
        opacity: 1,
        transformOrigin: "center center",
        transition: "all .3s ease-out",
        "&:hover": {
          position: "relative",
        },
      },
    },
    
  },
  notselected: {
    "& span": {
      fontWeight: 400,
      color: `rgba(255, 255, 255, 0.8) !important`,
    },
    "& i": {
      color: `rgba(255, 255, 255, 0.8) !important`,
    },
  },
  selected: {
    // backgroundColor: "background: rgba(36, 41, 46, 0.8)",
    color: theme.palette.primary.contrastText,
    "& span": {
      fontWeight: "bold",
    },
    "& i": {
      color: theme.palette.secondary.contrastText,
    },
    "& :hover":{
      // backgroundColor:theme.palette.variants.customBlack
    }
  },
  sideItem: {
    "&:hover": {
      WebkitTransition: "all .3s ease-in-out",
      MozTransition: "all .3s ease-in-out",
      transition: "all .3s ease-in-out",
      // borderRadius: theme.spacing(1),
      "& span": {
        // color: theme.palette.primary.main,
      },
    },
    "& i": {
      padding: 2,
    },
  },
  sideItemSelected:{
    backgroundColor:`rgba(255, 255, 255, 0.22)`,
  },
  root: {
    "&:hover": {},
    "& i": {
      fontSize: 13,
      marginTop: "2px",
      color: theme.palette.primary.contrastText,
    },
    "& span": {
      fontSize: 14,
      fontWeight: "normal",
      color: `rgba(255, 255, 255, 1)`,
    },
  },
  rootChild:{
    "&:hover": {},
    paddingLeft:50,
    "& i": {
      fontSize: 13,
      marginTop: "2px",
      color: theme.palette.primary.contrastText,
    },
    "& span": {
      fontSize: 14,
      fontWeight: "normal",
      color: `rgba(255, 255, 255, 1)`,
    },
  },
  menuIcon: {
    fontSize: "16px",
  },
}));

export default customMenuItemStyle;
