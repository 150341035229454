import { Grid } from "@material-ui/core";
import { AxiosError, AxiosResponse } from "axios";
import CustomInput from "components/ui/CustomInput/CustomInput";
import CustomModal from "components/ui/Modal/CustomModal";
import useForm, { IOptions } from "hooks/useForm";
import useHandleError from "hooks/useHandleError";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { createcourseModelErrors } from "utils/validationStates";
import { validatorAll } from "utils/validatorFunctions";
import useGetCourseModelDetails from "../../hooks/useGetCourseModelDetails";
import { editCourseModelApiService } from "../../services/coursemodel";
import { INormalResponse } from "../../types/response";
import { IEditCourseModel, ICourseModel } from "../../types/coursemodel";

export interface IAddCourseModelProps {
  editDialog: boolean;
  handleClose: () => void;
  item: ICourseModel;
}

const EditCourseModel = ({ editDialog, handleClose, item }: IAddCourseModelProps) => {
  const initFormState: IEditCourseModel = {
    courseModelName: item.courseModelName,
  };
  const { addToast } = useToasts();

  const [creating, setcreating] = useState(false);
  const { handleAppError } = useHandleError();
  const { courseModelDetails, getCourseModelDetails } = useGetCourseModelDetails();

  const { handleCreateFormInputChange, setInputs, hasError, inputs, err, clearForm, setError } =
    useForm<IEditCourseModel>(initFormState, true, createcourseModelErrors, "EditCourseModel");
  useEffect(() => {
    getCourseModelDetails.mutate(item.id);
  }, [item]);
  useEffect(() => {
    if (courseModelDetails) {
      setInputs((prevState) => ({
        ...courseModelDetails,
      }));
    }
  }, [courseModelDetails]);
  const handleConfirm = () => {
    setcreating(true);
    validatorAll(
      [{ name: "courseModelName", value: inputs.courseModelName, label: "Course Model Name" }],
      "EDITCourseModel",
      setError,
      err,
      hasError,
    );
    if (!hasError.current) {
      setcreating(false);
      return;
    }
    if (Object.keys(hasError?.current).length > 0) {
      setcreating(false);

      return;
    }
    const newPayload = {
      courseModelName: inputs.courseModelName,
    };
    editCourseModelApiService(newPayload, item.id)
      .then((res: AxiosResponse<INormalResponse>) => {
        setcreating(false);
        if (res.data.hasErrors) {
          addToast(res.data.description, {
            appearance: "error",
          });
        } else {
          addToast("Course Model Updated", {
            appearance: "success",
          });
        }
        handleClose?.();
      })
      .catch((axiosError: AxiosError) => {
        handleAppError(axiosError, err);
        setcreating(false);
      });
  };
  const handleToggleEditDialog = () => {
    handleClose?.();
  };
  return (
    <CustomModal
      open={editDialog}
      handleCancel={handleToggleEditDialog}
      handleClose={handleToggleEditDialog}
      title="Edit Course Model"
      showCancel
      showConfirm
      confirmText="Save"
      showDividers={true}
      confirmLoading={creating}
      confirmDisabled={creating}
      handleConfirm={handleConfirm}
      content={
        <>
          <div>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <div className="mt-2">
                  <CustomInput
                    errorText={err.courseModelName}
                    showError={err.courseModelName?.length > 0}
                    value={inputs?.courseModelName}
                    handleChange={(e) => handleCreateFormInputChange?.("courseModelName", "Course Model Name", e)}
                    name="courseModelName"
                    labelText="Course Model Name"
                    id="courseModelName"
                    placeholder="Course Model Name"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      }
    />
  );
};
export default EditCourseModel;
