import { cryptographyService } from "./globalUtils";

export class SecureStorage {
  storeItem = (key: string, value: string) => localStorage.setItem(key, value);
  getItem = (key: string) => localStorage.getItem(key);
  removeItem = (key: string) => localStorage.removeItem(key);
  clearAll = () => localStorage.clear();

  storeItemEncrypt = (key: string,value: string) => {
    const encrypted = cryptographyService().encrypt(value);
    localStorage.setItem(key, encrypted)
  }
  storeObj = (key: string, value: any) => {
    const encrypted = cryptographyService().encrypt(value);
    localStorage.setItem(key, encrypted)
  };
  getItemDecrypt = (key: string) => {
    const gotten = localStorage.getItem(key);
    if (gotten) {
      const decrypted =  cryptographyService().decrypt(gotten) as unknown as string;
      const parsed = decrypted;
      return parsed;
    } else {
      return null;
    }
  }
  retrieveObj = (key: string) => {
    const gotten = localStorage.getItem(key);
    if (gotten) {
      const decrypted =  cryptographyService().decrypt(gotten) as unknown as string;
      const parsed = decrypted;
      return parsed;
    } else {
      return null;
    }
  };
}
