import { makeStyles } from '@material-ui/core';
import { ITheme } from '../../../theme';

const customInputStyle = makeStyles((theme:ITheme) => ({
    root: {
        borderRadius: '4px',
        marginBottom:'0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
          boxShadow:'0px 4px 10px 3px rgba(0, 0, 0, 0.11)'
        },
       fontSize:14
    },
    textField:{
        
    },
    inputErrorContainer:{
        display:'flex',
        marginTop:'-7px'
    },
    multilineInput:{
        paddingLeft:'15px',
    },
    input:{
        paddingLeft:'20px',
        paddingTop:'15px',
        paddingBottom:'15px',
        fontSize:14,
        color:theme.palette.variants.primvarVariantThree,
        [theme.breakpoints.down("xl")]: {
            // width:300
          },
    },
    inputLabel:{
        fontWeight:400,
        textTransform:"capitalize",
        position:'absolute',
        marginTop:'-5px',
        fontSize:14,
        color:theme.palette.variants.primaryBlack
    },
    notchedOutline:{
        top:0
    },
    formControl:{
        // width:'100%'
        
    },
    endIcon:{
        marginTop: '-52px',
        position: 'absolute',
        right: '7px',
        cursor: 'pointer'
    },
    errorLabel:{
        color:theme.palette.warning.main,
        marginLeft:5,
    },
    inputerrorIcon:{
        marginTop:-1
    },
    inputContainer:{
        marginBottom:'8px',
        '& .MuiFormControl-marginNormal':{
            marginTop:5
        }
    }
}));

export default customInputStyle;