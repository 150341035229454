// import mainbrand from "assets/svg/mainbrand.svg";
import mainbrand from "assets/images/edutech_alt.png";
import React from "react";
import "./PreLoader.css";
export interface IPreLoaderProps {
  caption?: string;
}
const PreLoader = ({ caption = "Edutech." }: IPreLoaderProps) => {
  return (
    <div className="preloader2">
      <div className="loader">
        <div className="loader__figure"></div>
        <p className="loader__label">
          <div className="d-inline-flex align-baseline text-center align-items-center justify-content-center">
            <div>
              <img src={mainbrand} width={"200px"} />
            </div>
            <div>
              <div></div>
            </div>
          </div>
        </p>
      </div>
    </div>
  );
};

export default PreLoader;
