import { makeStyles, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

const customDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    customDialogRoot: {
      "& button": {
        fontSize: 13,
      },
    },
    paper: { minWidth: "700px" },
  }),
);
export default customDialogStyles;
