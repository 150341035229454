export const drawerWidth = 250;

//  API ROUTES
export const AUTH = "auth";
export const ADMIN = "admin";
export const SIGNIN = "signin";
export const ROLES = "roles";
export const DASHBOARD = "dashboard";
export const CLIENTS = "clients";

//  OTHER CONSTANTS

export const AUTH_TOKEN = "HTUA_back";
export const ZZiB = "ZZiB_back";
export const TEMP = "temp_back";

export const AUTH_USER = "resuhtua---";

export const initOptions = {
  id: "",
  label: "",
  value: "",
};

export const initAltOptions = {
  label: "",
  value: "",
};

export const initPager = {
  totalItems: 0,
  currentPage: 0,
  pageSize: 10,
  totalPages: 0,
  startPage: 1,
  endPage: 0,
  startIndex: 0,
  endIndex: 0,
  pages: [],
  loadingItems: true,
  errorInLoading: false,
};
export const entries = [
  {
    label: "10 Entries",
    value: 10,
  },
  {
    label: "20 Entries",
    value: 20,
  },
  {
    label: "50 Entries",
    value: 50,
  },
  {
    label: "100 Entries",
    value: 100,
  },
];

export const optionsConstants = {
  label: "",
  value: "",
  id: 0,
};
