import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { validator } from 'utils/validatorFunctions';


export interface IOptions {
  id: number;
  label: string;
  value: any;
}

const useForm = <T, S = null>(initState: T, shouldValidate: boolean, initErrors: any, key: string) => {
  const [inputs, setInputs] = useState<T>(initState);
  const [err, setError] = useState(initErrors);
  const hasError = useRef<any>(null);

  const handleCreateFormInputChange = (
    input: string,
    label: string,
    e: React.ChangeEvent<HTMLInputElement>,
    match?: boolean,
    password?:string
  ) => {
    const { target } = e;
    setInputs((prevState) => ({
      ...prevState,
      [input]: target?.value
    }));
    if (shouldValidate) {
      if (match) {
        validator(
          { name: input, value: target.value, label: label, password: password },
          key,
          setError,
          err
        );
      } else {
        validator({ name: input, value: target.value, label: label }, key, setError, err);
      }
    }
  };
  const handleCreateFormSelectChange = (input: string, label: string, e: S | IOptions | any,callback?: (e:S | IOptions | any)=>void) => {
    setInputs((prevState) => ({
      ...prevState,
      [input]: e
    }));
    if (shouldValidate) {
      validator({ name: input, value: e.value, label: label }, key, setError, err);
    }
    callback?.(e)
  };

  const handleCreateFormCheckboxChange = (input: string, label: string,e: React.ChangeEvent<HTMLInputElement>,) => {
    const { target } = e;
    setInputs((prevState) => ({
      ...prevState,
      [input]: target.checked
    }));
      validator({ name: input, value: target.checked, label: label }, key, setError, err);
    
  };

  const handleDateChange = (date: MaterialUiPickersDate, input: string, label: string) => {

   
    setInputs((prevState) => ({
      ...prevState,
      [input]: dayjs(date).format('YYYY-MM-DD')
    }));
    if (shouldValidate) {
      validator({ name: input, value: dayjs(date).format('YYYY-MM-DD'), label: label }, key, setError, err);
    }
  }
  const clearForm = () =>{
    setInputs(initState)
    setError(initErrors)

  }

  return { setInputs,handleDateChange,handleCreateFormCheckboxChange, handleCreateFormInputChange,clearForm, hasError, inputs, err, setError, handleCreateFormSelectChange };
};

export default useForm;
