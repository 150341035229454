import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
const pageLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageCardRoot: {
      paddingLeft:theme.spacing(1),
      paddingRight:theme.spacing(4),
      [theme.breakpoints.down("xs")]: {
        paddingLeft:0,
        paddingRight:0,
        marginBottom:theme.spacing(3),
      },
      "& .backIcon":{
        marginTop:-3
      }
    },
    
  })
);
export default pageLayoutStyles;
