import { Location } from "history";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

class ScrollToTop extends React.Component<RouteComponentProps> {
  public componentDidUpdate(prevProps: { location: Location<unknown> }) {
    if (this.props.history.location !== prevProps.location) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
