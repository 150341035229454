// import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
// import defaultTheme from "./default";

// let theme = createTheme({ ...defaultTheme, ...defaultTheme.overrides })
// export default {
//   default: responsiveFontSizes(theme),
// };
import { Theme } from "@material-ui/core";
import { createTheme, responsiveFontSizes, ThemeOptions } from "@material-ui/core/styles";
import { Palette } from "@material-ui/core/styles/createPalette";
import defaultTheme from "./default";

interface IPalette extends Palette {
  variants: {
    grey: string;
    tableHeader: string;
    primaryVariantOne: string;
    primaryVariantTwo: string;
    primvarVariantThree: string;
    primvarVariantFour: string;
    primvaryVariantFive: string;
    deepBlueFive: string;
    primaryBlack: string;
    deepBlueThree: string;
  };
}
export interface ITheme extends Theme {
  palette: IPalette;
}
export interface IThemeOptions extends ThemeOptions {
  palette: IPalette;
}
// let theme = createTheme({ ...defaultTheme, ...defaultTheme.overrides } as unknown as IThemeOptions)
// export default {
//   default: responsiveFontSizes(theme),
// };
let theme = createTheme({ ...defaultTheme, ...defaultTheme.overrides });
export default {
  default: responsiveFontSizes(theme),
};
