import { Box, TabProps, Tabs } from "@material-ui/core";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { ITabPanels, ITabs } from "types/TopNavigation";

const AntTabs = withStyles(theme => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    "& button": {
      "& span": {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: "auto",
      },
    },
  },
  indicator: {
    backgroundColor: "#1354D3",
    height:4,
    marginLeft: -5,
  },
}))(Tabs);

const AntTab = withStyles((theme: Theme) => ({
  root: {
    textTransform: "capitalize",
    minWidth: 72,
    fontWeight: "normal",
    marginRight: theme.spacing(4),
    paddingRight: 10,
    paddingLeft: 0,
    "&:hover": {
      opacity: 0.5,
    },
    color:"#000000",
    fontSize:15,
    "&$selected": {
      color: theme.palette.primary.dark,
      fontWeight: 600,
    },
    "&:focus": {
      opacity: 0.5,
      outline: "none",
    },
  },
  selected: {},
}))((props: TabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
    paddingLeft: (props: any) => `${props.tabLeft}px`,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
}));

const TabPanel = (props: {
  [x: string]: any;
  key?: any;
  children?: React.ReactChild | React.ReactNode;
  value?: number;
  index?: number;
}) => {
  const { children, value, index, ...other } = props;
  const realValue = value;
  const realIndex = index;
  return (
    <div
      role="tabpanel"
      hidden={realValue !== realIndex}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {realValue === realIndex && (
        <Box>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
interface TopNavigationProps {
  tabsRoutes?: string[];
  id?: string | null;
  handlesetTabIndex?: (index: number) => void;
  updateId?: (index: number) => void;
  tabs?: ITabs[];
  tabPanels?: ITabPanels[];
  tabIndex?: number;
  history?: any;
  tabLeft?: number;
  rightContent?: JSX.Element;
}
const TopNavigation = (props: TopNavigationProps) => {
  const classes = useStyles(props);
  const allTabs = props.tabsRoutes;
  const [showScrollButton, setshowScrollButton] = useState<"auto" | "desktop" | "on" | "off">("off");

  useEffect(() => {
    if (navigator) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        setshowScrollButton("on");
      }
    }
  }, [showScrollButton]);

  const setTab = (index: number) => {
    if (index !== undefined) {
      props.handlesetTabIndex?.(index);
      const route = allTabs?.[index];
      if (route) {
        props?.history.push(route);
      }
      props.updateId?.(index);
    }
  };

  const renderAntTabs = (tabs: ITabs[]) => {
    const arr = [];
    for (const i in tabs) {
      const hidden = tabs[i].hidden;
      const label = tabs[i].label;
      arr.push(<AntTab hidden={hidden} key={i} label={label} />);
    }
    return arr;
  };

  const renderTabPanels = (tabPanels: ITabPanels[]) => {
    const arr = [];
    for (const i in tabPanels) {
      arr.push(
        <TabPanel value={props.tabIndex} key={i} index={tabPanels?.[i].index}>
          {tabPanels?.[i].component}
        </TabPanel>,
      );
    }
    return arr;
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs
          value={props.tabIndex}
          onChange={(e, index) => setTab(index)}
          variant="scrollable"
          scrollButtons={showScrollButton}
          aria-label="ant example"
        >
          {props.tabs && renderAntTabs(props.tabs)}
          <div>
            <p style={{ right: 0 }} className="  position-absolute">
              {props.rightContent}
            </p>
          </div>
        </AntTabs>

        {props.tabPanels && renderTabPanels(props.tabPanels)}
      </div>
    </div>
  );
};
export default TopNavigation;
