import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    animationDuration: '550ms',
  },
}));

function VigilearnCustomCircleLoader(props:any) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        size={props?.size ?? 20}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export interface ICustomCircleLoaderProps {
  size?:number,
  color?:string,
  useThemeColor?:boolean
}
export const CustomCircleLoader =({size=20,color,useThemeColor}:ICustomCircleLoaderProps) => {
  const classes = useStyles();
  const appTheme = useTheme()
  return (
      <VigilearnCustomCircleLoader 
      size={size}
      style={{
        color: useThemeColor ? appTheme.palette.primary.main : color,
      }} />
  );
}
