import { useAuth0 } from "@auth0/auth0-react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import mainbrand from "assets/svg/mainbrand.svg";
import mainbrand from "assets/images/EduTech_Logo_BLUE.svg";
import CustomButton from "components/ui/CustomButton/CustomButton";
import { Space } from "components/ui/Space/Space";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import authLayoutStyles from "styles/components/authLayoutStyles";
import loginStyles from "styles/loginStyles";
import AuthContext from "../../context/AuthContext";
import { IUser } from "../../types/user";
export const Login = () => {
  const classes = loginStyles();
  const history = useHistory();
  const { auth } = useContext(AuthContext);
  const { loginWithRedirect, user } = useAuth0<Omit<IUser, "gender">>();

  const [loading, setLoading] = useState(false);
  const AuthLayoutClasses = authLayoutStyles();

  useEffect(() => {
    // if(auth !== "") {
    //   history.push("/app/home")
    // }
  }, [user]);

  const handleLogin = () => {
    setLoading(true);
    loginWithRedirect({ screen_hint: "login", redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URL });
    return;
  };
  return (
    <Container component="main">
      <div className={classes.paper}>
        <div className={AuthLayoutClasses.mainBrand404.concat(" ms-0")}>
          <Grid container>
            <Grid item md={12} className="text-center">
              <div>
                <img src={mainbrand} alt="mainBrand" />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.formRoot}>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Typography className="proceed">Click the login button to proceed</Typography>
          <Space top={5} />
          <div className={classes.form}>
            <Grid container className="mt-3">
              <Grid item md={12} xs={12} className="text-center">
                <CustomButton
                  borderRadius={4}
                  loading={loading}
                  disabled={loading}
                  onClick={handleLogin}
                  text="Login"
                />
              </Grid>
            </Grid>
            <Space top={20} />
          </div>
        </div>
        <Grid container>
          <Grid
            item
            md={12}
            xs={12}
            className="text-start mt-3"
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            <Typography>&copy; {new Date().getFullYear()} Edutech - All Rights Reserved.</Typography>
            <Typography>
              Powered by{" "}
              <a target={"_blank"} href="https://vigilearn.com">
                Vigilearn
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};
