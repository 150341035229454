import { makeStyles, Theme } from "@material-ui/core";
import defaultTheme, { lightPurple } from "theme/default";

const customSelectStyles = makeStyles((theme: Theme) => ({
  selectinputLabel: {
    fontSize: 12,
    fontWeight: 400,
    // top:'-7.5px',
    position: "relative",
  },
  selectErrorText: {
    color: theme.palette.warning.main,
    marginLeft: 5,
  },
  selectErrorBorder: {
    border: "1px solid red !important",
  },
  selectinputerrorIcon: {
    marginTop: -1,
  },
  selectErrorTextContainer: {
    marginTop: "-15px",
    marginBottom: "15px",
    display: "flex",
  },
}));

export default customSelectStyles;
export const countryOptions = [
  { value: "ocean", label: "Nigeria", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Ghana", color: "#0052CC", isDisabled: false },
  { value: "purple", label: "Mexico", color: "#5243AA" },
  { value: "red", label: "Uganda", color: "#FF5630", isFixed: true },
  { value: "orange", label: "China", color: "#FF8B00" },
  { value: "yellow", label: "Japan", color: "#FFC400" },
  { value: "green", label: "India", color: "#36B37E" },
  { value: "forest", label: "Congo", color: "#00875A" },
  { value: "slate", label: "America", color: "#253858" },
  { value: "silver", label: "Tunisia", color: "#666666" },
];

export const colourStyles = (
  showError?: boolean,
  width?: number,
  paddingTop?: number,
  paddingBottom?: number,
  showBackground?: boolean,
  height?: number | string,
  minheight?: number,
  backgroundColor?: string,
  border?: string,
  marginTop?: number,
) => {
  return {
    input: (styles: any) => ({
      ...styles,
      zIndex: 9999,
    }),
    placeholder: (styles: any) => ({
      ...styles,
    }),
    indicatorSeparator: (styles: any) => ({ display: "none" }),
    multiValue: (styles: any, state: any) => {
      return {
        ...styles,
        borderRadius: "8px",
        border: "1px solid #B9B9B9",
      };
    },
    multiValueRemove: (styles: any, state: any) => ({
      ...styles,
      color: defaultTheme.palette.primary.main,
      cursor: "pointer",
      ":hover": {
        ...styles,
      },
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      width: width || "100%",
      height: height || 52,
      minheight: minheight || 52,
      borderColor: "rgba(0, 0, 0, 0.23)",
      backgroundColor: showBackground ? lightPurple : backgroundColor,
      border: showError ? "1px solid red !important" : border,
      boxShadow: state?.isSelected && "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
      marginBottom: "18px",
      borderRadius: "4px",
      paddingTop: paddingTop ?? "8px",
      paddingBottom: paddingBottom ?? "8px",
      paddingLeft: "20px",
      marginTop: marginTop ?? "7px",
      fontSize: 15,
      fontWeight: 300,
      ":hover": {
        border: `1px solid ${defaultTheme.palette.primary.main}`,
        boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
      },
      ":focus": {
        border: `2px solid ${defaultTheme.palette.primary.main} !important`,
        boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
      },
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
  };
};
