export const loginErrors = {
  email: "",
  password: "",
  all: new Set(),
};

export const forgotPasswordErrors = {
  email: "",
  all: new Set(),
};

export const createCountryErrors = {
  name: "",
  all: new Set(),
};
export const createDegreeErrors = {
  name: "",
  all: new Set(),
};
export const createDurationErrors = {
  category: "",
  all: new Set(),
};
export const createProductErrors = {
  productCode: "",
  productName: "",
  all: new Set(),
};
export const createSpecializationErrors = {
  specializationName: "",
  all: new Set(),
};
export const createCategoryErrors = {
  categoryName: "",
  categoryDescription: "",
  all: new Set(),
};
export const createScholarshipErrors = {
  scholarshipName: "",
  type: "",
  figure: "",
  all: new Set(),
};
export const createProgramErrors = {
  programDescription: "",
  programName: "",
  all: new Set(),
};
export const editProgramErrors = {
  courseTypeName: "",
  all: new Set(),
};
export const editCohortErrors = {
  intake: "",
  applicationStartDate: "",
  applicationEndDate: "",
  all: new Set(),
};
export const createCourseTypeErrors = {
  description: "",
  name: "",
  all: new Set(),
};
export const editCourseTypeErrors = {
  description: "",
  name: "",
  all: new Set(),
};
export const createcourseModelErrors = {
  courseModelName: "",
  all: new Set(),
};
export const createRoleErrors = {
  roleName: "",
  roleType: "",
  description: "",
  selectedPermissions: "",
  all: new Set(),
};

export const editRoleErrors = {
  roleName: "",
  roleType: "",
  description: "",
  all: new Set(),
};

export const editCountryErrors = {
  name: "",
  all: new Set(),
};

export const editDegreeErrors = {
  name: "",
  all: new Set(),
};

export const editDurationErrors = {
  category: "",
  all: new Set(),
};

export const editProductErrors = {
  productCode: "",
  productName: "",
  all: new Set(),
};

export const editSpecializationErrors = {
  specializationName: "",
  all: new Set(),
};

export const editCategoryErrors = {
  categoryName: "",
  categoryDescription: "",
  all: new Set(),
};

export const editScholarshipErrors = {
  scholarshipName: "",
  type: "",
  figure: "",
  all: new Set(),
};

export const addTestimonialErrors = {
  PersonName: "",
  TestimonialComment: "",
  WorkPlaceInstitution: "",
  all: new Set(),
};
export const addInstructorErrors = {
  InstructorName: "",
  InstructorBio: "",
  CourseApplicationId: "",
  InstitutionId: "",
  all: new Set(),
};
export const addSchoolSettingsErrors = {
  InstitutionId: "",
  TenancyUrl: "",
  ApiCode: "",
  ApiUrl: "",
  SchoolCode: "",
  SchoolName: "",
  SchoolAddress: "",
  SchoolEmailAddress: "",
  ApiKey: "",
  ApplyApiUrl: "",
  all: new Set(),
};
export const editInstructorErrors = {
  InstructorName: "",
  InstructorBio: "",
  InstitutionId: "",
  CourseApplicationId: "",
  all: new Set(),
};
export const editMethodOfStudyErrors = {
  name: "",
  all: new Set(),
};
export const createMethodOfStudyErrors = {
  name: "",
  all: new Set(),
};
export const addCurriculumErrors = {
  CurriculumTitle: "",
  CurriculumDescription: "",
  CourseApplicationId: "",
  InstitutionId: "",
  all: new Set(),
};
export const editCurriculumErrors = {
  CurriculumTitle: "",
  CurriculumDescription: "",
  CourseApplicationId: "",
  InstitutionId: "",
  all: new Set(),
};
export const addInstitutionErrors = {
  Name: "",
  Email: "",
  WebsiteUrl: "",
  ExternalLink: "",
  ApplyPortalUrl: "",
  LearningModel: "",
  Location: "",
  InstitutionCode: "",
  Description: "",
  ScholarshipAmount: "",
  all: new Set(),
};
export const editInstitutionErrors = {
  Name: "",
  Email: "",
  LearningModel: "",
  Location: "",
  InstitutionCode: "",
  Description: "",
  ExternalLink: "",
  ApplyPortalUrl: "",
  ScholarshipAmount: "",
  all: new Set(),
};
export const createAdminUserErrors = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  roleId: "",
  userName: "",
  password: "",
  confirmPassword: "",
  country: "",
  gender: "",
  all: new Set(),
};

export const editAdminUserErrors = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  roleId: "",
  userName: "",
  all: new Set(),
};
export const updateAdminProfileErrors = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  roleId: "",
  userName: "",
  country: "",
  all: new Set(),
};
export const addCourseErrors = {
  CourseTitle: "",
  CourseApplicationCode: "",
  CourseFee: "",
  ApplicationFee: "",
  ApplicationFormURL: "",
  CourseDuration: "",
  CourseDescription: "",
  CourseRequirements: "",
  FeesAndFinance: "",
  CourseAndCountry: "",
  ExternalLink: "",
  CourseAndMethodOfStudy: "",
  CourseAndCourseType: "",
  StartDate: "",
  EndDate: "",
  InstitutionId: "",
  Degrees: "",
  CourseAndSpecialization: "",
  CourseAndDuration: "",
  all: new Set(),
};
export const editCourseErrors = {
  CourseTitle: "",
  CourseApplicationCode: "",
  CourseFee: "",
  ApplicationFee: "",
  CourseDuration: "",
  CourseDescription: "",
  CourseRequirements: "",
  ExternalLink: "",
  StartDate: "",
  EndDate: "",
  InstitutionId: "",
  all: new Set(),
};
export const verifyAccountErrors = {
  password: "",
  confirmPassword: "",
  all: new Set(),
};
