import { Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React from "react";

export interface ICustomDateProps {
  date: string;
  time?: boolean;
  isFull?: boolean;
}

const CustomDate: React.FC<ICustomDateProps> = ({ time, isFull, date }) => {
  return (
    <>
      {dayjs(date).format(isFull ? "MMMM D, YYYY" : "MMMM, YYYY")}
      {time && (
        <>
          {" "}
          | <Typography variant="caption">{dayjs(date).format("h:mm a	")}</Typography>
        </>
      )}
    </>
  );
};

CustomDate.defaultProps = {
  time: true,
  isFull: true,
};
export default CustomDate;
