import SimpleCrypto from "simple-crypto-js";
// import moment from "moment";

export const isValidUrl = (value: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d%_])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator

  return !!pattern.test(value);
};
export const cryptographyService = () => {
  const simpleCrypto = new SimpleCrypto("T()o(){o){R}");
  return simpleCrypto;
};

export const numberWithCommas = (x?: string, currency?: string) => {
  if (x) {
    if (parseInt(x) === 0) return currency ? currency + " 0.00" : "0";
    // if(!isNaN(parseInt(x))) return x
    if (!x) return "";
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return isNaN(parseInt(x)) ? x : currency ? currency + parts.join(".") + ".00" : parts.join(".");
    // return
  } else {
    return "";
  }
};
export const getFileExtension = (file_name: string) => {
  let result = "";
  if (file_name) {
    return file_name.split(".").pop();
  } else {
    result = "";
  }
  return result;
};
export const getBaseUrl = () => {
  return process.env.REACT_APP_BASE_URL;
};
export const getAlternativeBaseUrl = () => {
  return process.env.REACT_APP_ALT_BASE_URL;
};
export const getSSOBaseUrl = () => {
  return process.env.REACT_APP_SSO_BASE_URL;
};
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener");
  if (newWindow) newWindow.opener = null;
};
export const disableReactDevTools = (): void => {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;
  if (process.env.NODE_ENV === "production") {
    if (typeof DEV_TOOLS === "object") {
      for (const [key, value] of Object.entries(DEV_TOOLS)) {
        DEV_TOOLS[key] = typeof value === "function" ? noop : null;
      }
    }
  }
};
// export const momentService = (d: string) => {
//   const date = moment(d).format("MMM Do YY");
//   return date;
// };

export const parseString = (value: string) => {
  let newValue = value.replace("/", "");
  // debugger
  const parsed = JSON.parse(newValue);
  return parsed;
};
