import failure from 'assets/svg/failed_to_load.svg';
import React from 'react';
import { Space } from '../../ui/Space/Space';


const ListError = () => {
    return(
        <div className="text-center  m-auto">
            <div >
             <h6 style={{color:'#18191F80'}} className="mt-3 mb-4 text-muted">Failure in fetching data</h6>

            <img  src={failure} alt="failed" width={120}/>
        </div>
        <Space bottom={80}/>
        </div>
    )
}

export default ListError;