import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@material-ui/core";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { disableReactDevTools } from "utils/globalUtils";
import MainLayout from "./components/hoc/MainLayout";
import { AppContextProvider } from "./context/AppContextProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Themes from "./theme";


disableReactDevTools();
const queryClient = new QueryClient();

const generateClassName = createGenerateClassName({
  productionPrefix: "--vigilearn-bko--",
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URL || ""}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
        cacheLocation="localstorage"
      >
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={Themes.default}>
        <AppContextProvider>
         
              <BrowserRouter>
              <ToastProvider autoDismiss autoDismissTimeout={6000} placement="top-center">

                <MainLayout />
                </ToastProvider>
              </BrowserRouter>
        </AppContextProvider>
      </ThemeProvider>
    </StylesProvider>
    </Auth0Provider>
  </QueryClientProvider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
