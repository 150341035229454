import { AxiosError } from "axios";
import { IBackendErrors, IfrontendErrors } from "../types/response";

function mergeBackendErrors(backendErrors: IBackendErrors[], frontEndError: any) {
  let defaultErr: IfrontendErrors = {
    all: new Set(),
    ...frontEndError,
  };
  const filtered = backendErrors.filter((item) => !item.message.toLowerCase().includes("invalid value"));
  for (const i in filtered) {
    defaultErr.all.add(filtered[i].field);
    defaultErr[filtered[i].field] = filtered[i].message;
  }
  return defaultErr;
}

export const handleError = (axiosError: AxiosError, frontEndError: any) => {
  const { request, response } = axiosError;
  let data = {};
  if (response === undefined) {
    // most likely there is a network error
    return {
      ...data,
      error: "Please check your network or try again one more time.",
    };
  }
  if (response) {
    data = {
      status: response.status,
      headers: response.headers,
      response: response.data || null,
    };

    if (response.status === 404 && response.data.errors && response.data.errors.length === 1)
      return {
        ...data,
        error: response.data.errors?.[0].description ?? "Invalid token or request please contact an Admin.",
      };
      if (response.status === 500 && response.data?.Message.includes("expired"))
      return {
        ...data,
        error:  "Invalid token or Token Expired.",
        type:"expired"
      };
    if (response.status === 404) return data;

    if (response.status === 400) {
      if (response.data?.errors) {
        if (response.data.errors.length <= 1) {
          return {
            ...data,
            error: response.data.errors[0]?.description,
          };
        } else {
          return {
            ...data,
            error: mergeBackendErrors(response.data?.errors, frontEndError),
          };
        }
      }else{
        return {
          ...data,
          error:response.data?.message
        }
      }
    }
    if (response.status === 401 && response.config.method === "patch") {
      return {
        ...data,
        error: response.data.errors?.[0].description ?? "Invalid token or request please contact an Admin.",
      };
    }

    return data;
  } else if (request) {
    return {
      ...data,
      ...axiosError.request,
      message: "Check internet connection",
    };
  } else {
    return { ...data, message: axiosError.message };
  }
};
