import { Paper, TableBody, TableCell, TableRow, Table, TableContainer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableContainer from "@material-ui/core/TableContainer";
import clsx from "clsx";
import React from "react";
import { IListTableHeader } from "types/listtable";
import ListError from "../../shared/Error/ListError";
import NoRecord from "../../shared/Error/NoRecord";
import ListShimmer from "../Loader/ListShimmer";
import Header from "./Header";
const useStyles = makeStyles({
  table: {
    minWidth: 700,
    padding: "5px",
    width: "100%",
    overflowX: "auto",
    "& .MuiTableCell-root": {
      marginLeft: "20px",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "15px 18px 15px 32px !important",
      // whiteSpace:'pre'
    },
  },
  container: {
    maxHeight: "80rem",
    "@media (max-width:1680px)": {
      maxHeight: "42rem",
    },
  },
  noLeftPaddingTable: {
    minWidth: 700,
    padding: "5px",
    width: "100%",
    overflowX: "auto",
    // marginLeft: "20px",
    // "& .MuiTableCell-root": {
    //   marginLeft: "20px",
    // },
    "& .MuiTableCell-sizeSmall": {
      padding: "15px 18px 15px 15px !important",
    },
  },
  tablePaper: {
    overflowX: "auto",
    marginTop: "10px",
  },
  tableCell: {
    borderBottom: 0,

    paddingTop: "60px",
  },
  tableCellError: {
    borderBottom: 0,
    paddingTop: "80px",
  },
});

interface ListTableProps<I = null> {
  style?: React.CSSProperties;
  isSearching?: boolean;
  showError?: boolean;
  content?: JSX.Element;
  headers?: IListTableHeader[];
  noRecordComponent?: JSX.Element;
  errorComponent?: JSX.Element;
  items?: any;
  loadingComponent?: JSX.Element;
  noRecordDescription?: string;
  marginLeft?: number;
  paddingLeft?: number;
  noLeftPadding?: boolean;
  marginRight?: number;
}
const ListTable: React.FC<ListTableProps> = ({
  marginLeft,
  paddingLeft,
  style,
  items,
  loadingComponent = <ListShimmer />,
  errorComponent = <ListError />,
  noLeftPadding = true,
  noRecordComponent,
  noRecordDescription,
  headers,
  content,
  isSearching,
  marginRight,
  showError,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.tablePaper}>
      <TableContainer style={style} className={classes.container}>
        <Table
          className={clsx(noLeftPadding ? classes.noLeftPaddingTable : classes.table)}
          size="small"
          aria-label="customized table"
          style={{
            marginLeft,
            paddingLeft,
          }}
        >
          <Header headers={headers} />

          {isSearching && (
            <TableBody>
              <TableRow>
                <TableCell align="left" colSpan={headers?.length} rowSpan={headers?.length}>
                  {loadingComponent}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!showError && !isSearching && items?.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={headers?.length}
                  rowSpan={headers?.length}
                  className={classes.tableCellError}
                >
                  {noRecordComponent || <NoRecord description={noRecordDescription} />}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!isSearching && !showError && items?.length > 0 && content}
          {showError && !isSearching && (
            <TableBody>
              <TableCell align="center" colSpan={headers?.length} className={classes.tableCell}>
                {errorComponent}
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default ListTable;
