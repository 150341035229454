import { makeStyles } from "@material-ui/core/styles";
import LoginImage from "../assets/images/loginimage.png";
import { ITheme } from "../theme";

const authStyles = makeStyles((theme: ITheme) => ({
  container: {
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      position: "relative",
    },
    overflowX: "hidden",
    width: "100%",
    WebkitOverflowScrolling: "touch",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  loginwriteupcontainer: {
    marginLeft: theme.spacing(12),
  },
  logotypeContainer: {
    backgroundImage: `url(${LoginImage})`,
    backgroundSize: "cover",
    backgroundColor: theme.palette.variants.deepBlueThree,
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  loginLogoContainer: {
    display: "flex",
    paddingLeft: theme.spacing(12),
    paddingTop: theme.spacing(3.5),
    position: "absolute",
    top: "0px",
    left: "0px",
    padding: "46px",
  },
  formContainer: {
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  signinform: {
    width: "65%",
    marginTop: "0%",
    [theme.breakpoints.down("md")]: {
      maxWidth: 320,
      minWidth: "160%",
      marginTop: "50%",
    },
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
export default authStyles;
