import CssBaseline from '@material-ui/core/CssBaseline';
import LinearProgress from '@material-ui/core/LinearProgress';
import routes from 'constants/routes';
import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import wrapperStyles from 'styles/wrapperStyles';
import menuitems from '../../constants/menuitems';
import SideNav from '../shared/SideNav';
import TopAppBar from '../shared/TopAppBar';
import GuardRoute from './guard';
import ScrollToTop from './ScrollToTop';

const Wrapper = () => {
  const classes = wrapperStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const menu = routes.map((route: any, index: number) => {
    return route.component ? (
      <GuardRoute key={index} path={route.path} exact={route.exact} component={route.component} />
    ) : null;
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopAppBar handleDrawerToggle={handleDrawerToggle} />

      <SideNav mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} menuItems={menuitems} />
      <ScrollToTop>
        <main className={classes.content}>
          {/* <div className={classes.toolbar} /> */}
          <>
            <Suspense
              fallback={
                <div className="ms-4 me-4 mt-3">
                  {' '}
                  
                  <LinearProgress />{' '}
                </div>
              }
            >
              <Switch>{menu}</Switch>
            </Suspense>
          </>
        </main>
      </ScrollToTop>
    </div>
  );
};
export default Wrapper;
