import { Grid, Paper, Typography } from "@material-ui/core";
import arrowback from "assets/svg/arrowback.svg";
import useMobile from "hooks/useMobile";
import React, { ReactNode } from "react";
import pageLayoutStyles from "styles/components/pageLayoutStyles";
export interface PageLayoutProps {
  children?: ReactNode;
  usePaddingLeft?: boolean;
  handleshowList?: () => void;
  viewForm?: boolean;
  pageTitle: string | JSX.Element;
  rightActions?: JSX.Element;
  alternativeAction?: JSX.Element;
  topSection?: JSX.Element;
  showWhiteBg?: boolean;
}
const PageLayout = ({
  children,
  viewForm,
  handleshowList,
  pageTitle,
  rightActions,
  alternativeAction,
  topSection,
  showWhiteBg = true,
  usePaddingLeft = true,
}: PageLayoutProps) => {
  const classes = pageLayoutStyles();
  const { isMobile } = useMobile();

  return (
    <div>
      {/* Page Layout */}
      <div
        className={classes.pageCardRoot}
        style={{
          paddingLeft: !usePaddingLeft ? 0 : "",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ paddingBottom: "30px", display: "flex" }}>
            {viewForm && (
              <div
                style={{
                  paddingTop: "0.2rem",
                  marginRight: ".5rem",
                  cursor: "pointer",
                }}
                onClick={handleshowList}
              >
                <img className="backIcon" src={arrowback} />
              </div>
            )}
            <div>
              <Typography variant={"h4"}>{pageTitle}</Typography>
            </div>
          </div>

          <div>
            <div style={{ display: "inline-flex" }}>{rightActions}</div>
            {alternativeAction && <div style={{ marginLeft: 9, display: "inline-flex" }}>{alternativeAction}</div>}
          </div>
        </div>
      </div>
      <Grid container>
        <Grid item md={12} xs={12}>
          {showWhiteBg ? (
            <Paper elevation={0} style={{ height: "auto", maxHeight: "max-content" }}>
              <div>{topSection}</div>
              <div>{children}</div>
            </Paper>
          ) : (
            <div style={{ height: "auto", maxHeight: "max-content" }}>
              <div>{children}</div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default PageLayout;
