import { IRequests } from "../types/requests";

export const dummyRequestData: IRequests = {
  pageSize: 10,
};

export const initPager = {
  totalItems: 0,
  currentPage: 0,
  pageSize: 10,
  totalPages: 0,
  startPage: 1,
  endPage: 0,
  startIndex: 0,
  endIndex: 0,
  pages: [],
  loadingItems: true,
  errorInLoading: false,
};
