export const api = {
  INSTITUTION: "Institution",
  GET_ALL_INSTITUTIONS: "GetAllInstitution",
  GET_ALL_ACTIVE_INSTITUTIONS: "GetAllActiveInstitution",
  COURSE_APPLICATION: "CourseApplication",
  SYNC_APPLY_PORTAL: "SyncApplyPortal",
  COURSE_INTAKE_OPTIONS: "CourseIntakeOptions",
  GET_ALL_COURSES: "GetAllCourses",
  GET_ALL_INACTIVE_COURSES: "GetAllInactiveCourses",
  GET_ALL_DELETED_COURSES: "GetAllDeletedCourses",
  GET_ALL_ACTIVE_COURSES: "GetAllActiveCourses",
  EXPORT_APPLICANT_DATA: "ExportApplicantData",
  GET_COURSE_DETAIL_BY_ID: "GetCourseDetailById",
  GET_COURSE_TYPE_DETAIL_BY_ID: "GetCourseTypeById",
  GET_COURSE_MODEL_DETAIL_BY_ID: "GetCourseModelById",
  GET_INSTITUTION_BY_ID: "GetInstitutionById",
  GET_INSTITUTION_BY_CATEGORY: "GetInstitutionByCategory",
  GET_DELETED_INSTITUTION: "GetDeletedInstitution",
  GET_INACTIVE_INSTITUTION: "GetInactiveInstitution",
  GET_COHORT_YEARS: "GetCohortYears",
  FORGOT_PASSWORD: "ForgotPassword",
  CREATE_PRODUCT: "CreateProduct",
  CREATE_SPECIALIZATION: "CreateSpecialization",
  GET_ALL_COURSE_TYPE: "GetAllCoursetype",
  SIGNUPS: "Signups",
  GET_ALL_COURSE_MODELS: "GetAllCoursemodels",
  GET_ALL_COHORTS: "GetAllCohorts",
  GET_ALL_COURSE_TYPES: "GetAllCourseTypes",
  GET_ALL_ACTIVE_METHOD_OF_STUDIES: "GetAllActiveMethodOfStudies",
  GET_ALL_ACTIVE_COURSE_TYPES: "GetAllActiveCourseTypes",
  GET_ALL_METHOD_OF_STUDIES: "GetAllMethodOfStudies",
  PRODUCT: "Product",
  COUNTRY: "Country",
  COURSE_TYPE: "CourseType",
  DEGREE: "Degree",
  DURATION: "Duration",
  SPECIALIZATION: "Specialization",
  CATEGORY: "Category",
  SCHOLARSHIP: "Scholarship",
  METHOD_OF_STUDY: "MethodOfStudy",
  PROGRAM: "Program",
  COURSEMODEL: "CourseModel",
  CREATE_COURSE_MODEL: "CreateCourseModel",
  UPDATE_COURSE_MODEL: "UpdateCourseModel",
  DELETE_COURSE_MODEL: "DeleteCourseModel",
  CREATE_METHOD_OF_STUDY: "CreateMethodOfStudy",
  UPDATE_METHOD_OF_STUDY: "UpdateMethodOfStudy",
  DELETE_METHOD_OF_STUDY: "DeleteMethodOfStudy",
  CREATE_COURSE_TYPE: "CreateCourseType",
  UPDATE_COURSE_TYPE: "UpdateCourseType",
  DELETE_COURSE_TYPE: "DeleteCourseType",
  DELETE_PROGRAM_TYPE: "DeleteProgramType",
  SETTING: "Setting",
  GET_ALL_SETTING: "GetAllSetting",
  UPDATE_SETTING: "UpdateSetting",
  GET_COUNTRY_LIST: "GetCountryList",
  GET_CURRENCY_LIST: "GetCurrencyList",
  CREATE_SETTING: "CreateSetting",
  GET_ALL_SIGNUPS: "GetAllSignups",
  GET_ALL_COUNTRIES: "GetAllCountries",
  GET_ALL_ACTIVE_COUNTRIES: "GetAllActiveCountries",
  GET_ALL_DEGREES: "GetAllDegrees",
  GET_ALL_DURATIONS: "GetAllDurations",
  GET_ALL_ACTIVE_DEGREES: "GetAllActiveDegrees",
  GET_ALL_ACTIVE_DURATIONS: "GetAllActiveDurations",
  GET_SETTING: "GetSetting",
  GET_SIGNUP_DETAILS_BY_ID: "GetSignupDetailsBy",
  CREATE_COUNTRY: "CreateCountry",
  CREATE_DEGREE: "CreateDegree",
  CREATE_DURATION: "CreateDuration",
  CREATE_COURSE: "CreateCourse",
  UPDATE_COURSE: "UpdateCourse",
  DELETE_COURSE: "DeleteCourse",
  SYNC_COURSES: "sync-courses",
  SYNC_TENANTS: "sync-tenants",
  CREATE: "Create",
  DATA_EXPORT: "DataExport",
  EXPORT_SIGNUP_DATA: "ExportSignupData",
  CREATE_ROLE: "CreateRole",
  PROFILE_ADMIN_USER: "ProfileAdminUser",
  DASHBOARD: "Dashboard",
  CREATE_INSTITUTION: "CreateInstitution",
  GET_DASHBOARD_DATA: "GetDashboardData",
  GET_ALL_PRODUCTS: "GetAllProducts",
  GET_ALL_ACTIVE_PRODUCT: "GetAllActiveProduct",
  GET_ALL_SPECIALIZATION: "GetAllSpecialization",
  GET_ALL_ACTIVE_SPECIALIZATION: "GetActiveAllSpecialization",
  GET_PRODUCT_BY_ID: "GetProductById",
  GET_SPECIALIZATION_BY_ID: "GetSpecializationById",
  GET_ALL_CATEGORIES: "GetAllCategories",
  GET_ALL_ACTIVE_CATEGORIES: "GetAllActiveCategories",
  GET_ALL_SCHOLARSHIPS: "GetAllScholarships",
  GET_CATEGORY_BY_ID: "GetCategoryById",
  GET_SCHOLARSHIP_BY_ID: "GetScholarshipById",
  GET_COUNTRY_BY_ID: "GetCountryById",
  GET_DEGREE_BY_ID: "GetDegreeById",
  GET_DURATION_BY_ID: "GetDurationById",
  GET_USERS_BY_ID: "GetUsersById",
  CREATE_CATEGORY: "CreateCategory",
  UPDATE_CATEGORY: "UpdateCategory",
  DELETE_CATEGORY: "DeleteCategory",
  CREATE_SCHOLARSHIP: "CreateScholarship",
  UPDATE_SCHOLARSHIP: "UpdateScholarship",
  DELETE_SCHOLARSHIP: "DeleteScholarship",
  DELETE_COUNTRY: "DeleteCountry",
  DELETE_DEGREE: "DeleteDegree",
  DELETE_DURATION: "DeleteDuration",
  AUDIT: "Audit",
  CURRICULUM: "Curriculum",
  UPDATE_CURRICULUM: "UpdateCurriculum",
  UPDATE_COUNTRY: "UpdateCountry",
  UPDATE_DEGREE: "UpdateDegree",
  UPDATE_DURATION: "UpdateDuration",
  DELETE_COURSE_INTAKE_OPTIONS: "DeleteCourseIntakeOptions",
  UPDATE_COURSE_INTAKE_OPTIONS: "UpdateCourseIntakeOptions",
  CREATE_CURRICULUM: "CreateCurriculum",
  GET_CURRICULUM_BY_ID: "GetCurriculumById",
  GET_ALL_CURRICULUM: "GetAllCurriculum",
  INSTRUCTOR: "Instructor",
  UPDATE_INSTRUCTOR: "UpdateInstructor",
  CREATE_INSTRUCTOR: "CreateInstructor",
  GET_INSTRUCTOR_BY_ID: "GetInstructorById",
  GET_ALL_INSTRUCTORS: "GetAllInstructors",
  GET_APPLICANT_DETAILS_BY_SSO_SER_ID: "GetApplicantDetailsBySSOUserId",
  GET_APPLICANT_DETAILS_BY_EMAIL: "GetApplicantDetailsByEmail",
  UPDATE: "Update",
  UPDATE_INSTITUTION: "UpdateInstitution",
  UPDATE_PRODUCT: "UpdateProduct",
  UPDATE_SPECIALIZATION: "UpdateSpecialization",
  DELETE_SPECIALIZATION: "DeleteSpecialization",
  TESTIMONIAL: "Testimonial",
  CREATE_TESTIMONIAL: "CreateTestimonial",
  UPDATE_TESTIMONIAL: "UpdateTestimonial",
  GET_ALL_TESTIMONIALS: "GetAllTestimonials",
  GET_TESTIMONIAL_BY_ID: "GetTestimonialByID",
  APPLICANT: "Applicant",
  GET_ALL_APPLICANTS: "GetAllApplicants",
  GET_ALL_ADMIN_USERS: "GetAllAdminUsers",
  GET_USERS: "GetUsers",
  GET_ALL: "GetAll",
  ADMIN: "Admin",
  ROLE: "Role",
  GET_ALL_ROLES: "GetAllRoles",
  GET_ROLE_PERMISSIONS: "GetRolePermissions",
  MODULE_PERMISSION: "ModulePermission",
  USER: "User",
  IS_USER_ADMIN: "IsUserAdmin",
  TOKEN: "token",
  VERIFY_ACCOUNT: "VerifyAccount",
  DELETE_INSTITUTION: "DeleteInstitution",
  PROFILE_APPLICANT: "ProfileApplicant",
  UPDATE_USER: "UpdateUser",
  REMOVE_USER_FROM_ROLE: "RemoveUserFromRole",
  ADD_USER_FROM_ROLE: "AddUserFromRole",
  UPDATE_USER_IMAGE: "UpdateUserImage",
};
