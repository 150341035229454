import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ImportantDevices } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import Draggable from "react-draggable";
import customDialogStyles from "styles/customDialogStyles";
import useMobile from "../../../hooks/useMobile";
import CustomButton from "../CustomButton/CustomButton";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      cursor: "move",

      "& h6": {
        fontSize: 18,
        fontWeight: 600,
      },
      "& .modalCancel": {
        color: theme.palette.secondary.dark,
        fontSize: 16,
      },
    },
    // MuiDialogPaperWidthXs: {
    //   maxWidth: '844px'
    // },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.primary.main,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className="text-left ms-1">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
interface CustomModalProps {
  open: boolean;
  handleClickOpen?: () => void;
  handleClose?: () => void;
  content: string | JSX.Element;
  title?: string | JSX.Element;
  showCancel?: boolean;
  showConfirm?: boolean;
  confirmText?: string;
  description?: string | JSX.Element;
  showDividers?: boolean;
  handleConfirm?: () => void;
  confirmLoading?: boolean;
  confirmDisabled?: boolean;
  handleCancel?: () => void;
  cancelText?: string;
  minWidth?: number;
  // maxWidth?: DialogProps["maxWidth"];
  maxWidth?: number;
  handleClearFilter?: () => void;
  showClearFilter?: boolean;
}
const CustomModal: React.FC<CustomModalProps> = ({
  handleCancel,
  cancelText = "Cancel",
  confirmText = "Confirm",
  description,
  showDividers = true,
  handleConfirm,
  confirmLoading,
  confirmDisabled,
  minWidth = 500,
  showConfirm,
  showCancel,
  open,
  title,
  maxWidth = 800,
  handleClose,
  content = "Nice content",
  showClearFilter,
  handleClearFilter,
}) => {
  const classes = customDialogStyles();
  const { isMobile } = useMobile();

  return (
    <Dialog
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className={classes.customDialogRoot}
      fullWidth
      PaperProps={{
        style: { borderRadius: "0px", minWidth: isMobile ? "auto" : minWidth, maxWidth: maxWidth },
      }}
      // classes={{ paper: classes.paper}}
      aria-labelledby="draggable-dialog-title"
      open={open}
    >
      <DialogTitle id="draggable-dialog-title" onClose={handleClose}>
        {title && title}
      </DialogTitle>
      <DialogContent dividers={showDividers}>
        <div className="ms-2 me-2">
          {description && (
            <Typography gutterBottom variant="caption">
              {description}
            </Typography>
          )}
          <div style={{ marginTop: -15 }}>{content}</div>

          <div className="d-flex float-end mt-4 ms-2 me-2 mb-3">
            {showClearFilter && (
              <div
                onClick={handleClearFilter}
                style={{ position: "absolute", left: "5%" }}
                className="align-self-center  cursor-pointer"
              >
                <Typography variant="caption" className="modalCancel" color="error">
                  Clear All Filters
                </Typography>
              </div>
            )}
            {showCancel && (
              <div onClick={handleCancel} className="align-self-center cursor-pointer mb-3">
                <Typography variant="caption" className="modalCancel">
                  {cancelText}
                </Typography>
              </div>
            )}
            <div className="ms-3 mb-3">
              <CustomButton
                text={confirmText}
                height={40}
                loading={confirmLoading}
                disabled={confirmDisabled}
                onClick={handleConfirm}
                show={showConfirm}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default CustomModal;
