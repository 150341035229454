import { LinearProgress } from "@material-ui/core";
import { ErrorPage } from "pages/NotFound/ErrorPage";
import Activate from "pages/User/Activate";
import React, { FC, Suspense, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { ForgotPassword } from "../../pages/auth/ForgotPassword";
import { Login } from "../../pages/auth/Login";
// import { Login } from "../../pages/auth/Login";
import globalStyles from "../../styles/globalStyles";
import PreLoader from "../ui/Loader/PreLoader";
import AuthLayout from "./AuthLayout";
import ScrollToTop from "./ScrollToTop";
import Aux from "./_Aux";
interface MainLayoutProps {}
const MainLayout: FC<MainLayoutProps> = () => {
  const classes = globalStyles();
  const [loading, setloading] = useState(false);
  // const { isAuthenticated, getAccessTokenSilently, user } = useAuth0<Omit<IUser, "gender">>();
  // const { setAuthAndCache, updateCurrentUser, getUserCredentials, setUserCredentials } = useContext(AuthContext);
  //
  // const history = useHistory();
  // const getAuthToken = async () => {
  //   // debugger
  //   const accessToken = await getAccessTokenSilently();
  //   const current = getUserCredentials(AUTH_USER) as unknown as IUser;
  //   if (current && Object.keys(current).length > 0) {
  //     updateCurrentUser(current);
  //   }
  //   if (accessToken && accessToken !== "") {
  //     setAuthAndCache(`${`Bearer`} ${accessToken}`);
  //     if (user) {
  //       updateCurrentUser(user);
  //       setUserCredentials(user);
  //     }
  //     AxiosInstance.defaults.headers["Authorization"] = `${`Bearer`} ${accessToken}`;
  //   }
  //   if (user && accessToken) {
  //     setloading(false)
  //     history.push("/app/home");
  //   }
  // };
  // useEffect(() => {
  //   // if(isAuthenticated){
  //   //   setloading(true);
  //   // }
  //   getAuthToken();
  // }, [user]);
  return (
    <Aux>
      <div className={classes["@global"]}>
        {loading ? (
          <PreLoader />
        ) : (
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="ms-4 me-4">
                  {" "}
                  <LinearProgress />{" "}
                </div>
              }
            >
              <Switch>
                {/* All unthenticated Routes will go here */}
                {/* login, reset-password*/}

                <Route path={"/"} exact render={() => <Login />} />
                <Route path={"/forgotpassword"} exact render={() => <ForgotPassword />} />
                <Route path={"/user/activate"} exact render={() => <Activate />} />
                <Route path={"/app/"} render={() => <AuthLayout />} />
                <Route
                  render={() => (
                    <ErrorPage
                      caption="Oops we tried all we could!"
                      description="Kindly go back or hit refresh to try again."
                    />
                  )}
                />
              </Switch>
            </Suspense>
          </ScrollToTop>
        )}
      </div>
    </Aux>
  );
};
export default MainLayout;
