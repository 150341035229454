import { makeStyles, Theme } from "@material-ui/core";
import tinycolor from "tinycolor2";
const customButtonStyle = makeStyles((theme:Theme) => ({
    root: {
        width:'100%',
        padding:theme.spacing(2.2),
        color: '#ffffff',
        fontWeight: 600,
        boxShadow: 'none',
        textTransform: 'none',
        borderRadius: 10,
        fontSize:15.8,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor:theme.palette.primary.light
          },
    },
    default:{
        backgroundColor: theme.palette.primary.contrastText,
        border:`1.3px solid ${theme.palette.primary.main} !important`,
        color: tinycolor(theme.palette.primary.main).toHexString(),
        fontWeight:400,
        '&:hover': {
            backgroundColor:theme.palette.primary.light,
            color:theme.palette.primary.contrastText,
          },
    },
    disabled:{
        color: '#ffffff !important',
        backgroundColor: `${theme.palette.primary.main} !important`,
        opacity:0.8
    },
    enabled:{
        backgroundColor: `${theme.palette.primary.main} !important`,
        color:`${theme.palette.primary.contrastText} !important`,
        opacity:1
    },
    label: {
    }
}))

export default customButtonStyle;