import { Theme } from "@material-ui/core";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuRoot: {
      borderRadius: theme.spacing(1),
    },
    menuButton: {
      display: "inline-block",
      color: theme.palette.text.primary,
      "& div:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
);
export interface ICustomMenuWrapperProps extends Omit<MenuProps, "open"> {
  children?: React.ReactChild | React.ReactChild[];
  shouldCloseOnItemClick?: boolean;
  hasMultiple?: boolean;
  menuButton?: JSX.Element;
  borderRadius?: number;
  content?: string | undefined;
  transformOrigin?: MenuProps["transformOrigin"];
  anchorOrigin?: MenuProps["anchorOrigin"];
}
const CustomMenuWrapper: React.FC<ICustomMenuWrapperProps> = ({
  content,
  transformOrigin,
  anchorOrigin,
  borderRadius,
  menuButton,
  shouldCloseOnItemClick = true,
  hasMultiple,
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const newChildren = React.Children.toArray(children);
  // let { shouldCloseOnItemClick = true, horizontalPosition = 'left' } = props
  const classes = useStyles();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <div className={classes.menuButton} onClick={handleClick}>
        {menuButton}
      </div>
      <Menu
        elevation={8}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin || {
            vertical: "bottom",
            horizontal: "left",
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: "top",
            horizontal: "left",
          }
        }
        PaperProps={{
          style: {
            borderRadius: borderRadius,
          },
          classes: {
            rounded: classes.menuRoot,
          },
        }}
      >
        {hasMultiple ? (
          newChildren.map((child, index) => (
            <div onClick={shouldCloseOnItemClick ? handleClose : () => {}} key={index}>
              {child}
            </div>
          ))
        ) : (
          <div onClick={shouldCloseOnItemClick ? handleClose : () => {}}>{content}</div>
        )}
      </Menu>
    </Fragment>
  );
};

export default CustomMenuWrapper;
