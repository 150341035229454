import { useAuth0 } from "@auth0/auth0-react";
import { AxiosError, AxiosResponse } from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import AuthContext, { setLogout } from "../../context/AuthContext";
import AxiosInstance from "../../services/AxiosInstance";
import { isUserAdminApiService } from "../../services/user";
import { INormalResponse } from "../../types/response";
import { IUser } from "../../types/user";
import { returnSubId } from "../../utils/otherUtils";
import { SecureStorage } from "../../utils/storage";
import PreLoader from "../ui/Loader/PreLoader";
import Wrapper from "./Wrapper";

const AuthLayout = () => {
  const history = useHistory();
  const [loading, setloading] = useState(true);
  const idleTimer = useRef<any>(null);
  const { addToast } = useToasts();
  const secureStorage = new SecureStorage();

  const { getAccessTokenSilently, isAuthenticated, logout, user } = useAuth0<Omit<IUser, "gender">>();
  const { setAuthAndCache, auth } = useContext(AuthContext);
  useEffect(() => {
    (() => {
      try {
        if (isAuthenticated) {
          // debugger;
          getAccessTokenSilently().then((accessToken: string) => {
            // debugger;
            if (accessToken && accessToken !== "") {
              setAuthAndCache(`${`Bearer`} ${accessToken}`);
              AxiosInstance.defaults.headers["Authorization"] = `${`Bearer`} ${accessToken}`;
              if (user) {
                // debugger;
                const userId = returnSubId(user.sub) as unknown as string;
                isUserAdminApiService(userId)
                  .then(
                    (res: AxiosResponse<INormalResponse<{ isAdmin: boolean; roleId: string; roleName: string }>>) => {
                      // debugger;
                      if (!res.data.payload.isAdmin) {
                        addToast("Applicants have no access here.", {
                          appearance: "error",
                          autoDismiss: true,
                        });
                        secureStorage.clearAll();

                        setTimeout(() => {
                          setloading(false);
                          logout({
                            returnTo: process.env.REACT_APP_AUTH0_LOG_OUT_REDIRECT_URL as unknown as string,
                            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                          });
                        }, 300);
                      } else {
                        // debugger;
                        setloading(false);
                        history.push("/app/home");
                      }
                    },
                  )
                  .catch((axiosError: AxiosError) => {
                    if (axiosError) {
                      history.push("/");
                    }
                  });
              }
            }
          });
        }
      } catch (e) {
        console.log("exception", e);
      }
    })();
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (history.location.search.includes("code") && loading === false) {
      if (auth === "") {
        history.push("/");
      }
    }
  }, [history]);

  const handleOnIdle = (event: Event) => {
    // log out function
    addToast("Session has Expired, please login again", {
      appearance: "error",
    });
    setAuthAndCache("");
    setTimeout(() => {
      setLogout();
      logout({
        returnTo: process.env.REACT_APP_AUTH0_LOG_OUT_REDIRECT_URL as unknown as string,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      });
    }, 500);
  };

  return (
    <>
      {loading ? (
        <PreLoader />
      ) : (
        <>
          <IdleTimer
            ref={(ref: IdleTimer) => {
              idleTimer.current = ref;
            }}
            // timeout={5000} //3 minutes === 240000ms
            timeout={15 * 60000} //15minutes
            onIdle={handleOnIdle}
            debounce={250}
            crossTab={{
              emitOnAllTabs: true,
            }}
          />
          <Wrapper />
        </>
      )}
    </>
  );
};
export default AuthLayout;
