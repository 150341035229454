import noRecord from "assets/svg/norecordicon.svg";
import React from "react";
import noRecordStyles from "styles/components/noRecordStyles";

export interface INoRecord {
  description?: string;
}
const NoRecord = ({ description }: INoRecord) => {
  const classes = noRecordStyles();
  return (
    <div className={classes.noRecordRoot}>
      <div style={{ color: "#aba4a4" }}>
        <p>{description}</p>
      </div>
      <div>
        <img src={noRecord} className="mt-3" alt="no record" style={{ width: "100px" }} />
      </div>
    </div>
  );
};

export default NoRecord;
