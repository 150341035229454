export const returnSerialNumber = (index: number, page: number, pageSize: number) => {
  if (page > 2) {
    return 1 + index + (page - 1) * pageSize;
  } else if (page === 2) {
    return 1 + index + pageSize;
  } 
    return ++index;
  
};

export const getObjectFromString = (stringToParse: string) => {
  if (typeof stringToParse === "string") {
    let currentKey = "";
    const keyValPairArr = stringToParse.replace("{", "").replace("}", "").split(":");
    return keyValPairArr.reduce((obj: any, current, index, arr) => {
      const previousKey = currentKey;
      const arrKeyVal = current.trim().split(",") as any;
      currentKey = index !== arr.length - 1 ? arrKeyVal?.pop().trim() : "";
      const previousVal = arrKeyVal.join(",");
      if (previousKey && previousVal !== "") obj[previousKey] = previousVal;
      return obj;
    }, {});
  } else {
    return stringToParse || {};
  }
};

export const returnSubId = (sub: string) => {
  // user.sub?.split("|")[1]
  return sub;
};

export const returnObjValue = (item: any, key: string) => {
  const obj = getObjectFromString(item);
  return obj?.[key];
};