import React, { createContext, FC, ReactChild, useEffect, useState } from "react";
import { IApplicantDetails } from "types/applications";
import { AUTH_TOKEN, AUTH_USER, TEMP, ZZiB } from "../constants/appConstants";
import { IUser } from "../types/user";
import { cryptographyService } from "../utils/globalUtils";
import { SecureStorage } from "../utils/storage";

const secureStorage = new SecureStorage();

const initState = {
  auth: secureStorage.getItem(AUTH_TOKEN),
  currentUser: {} as IUser,
  applicantDetails: {} as IApplicantDetails,
};
type initState = {
  auth?: string;
  setAuthAndCache: (v: string) => void;
  setUserCredentials: (userObj: IUser) => void;
  getUserCredentials: (key: string) => void;
  setLogout: () => void;
  currentUser: IUser;
  applicantDetails: IApplicantDetails;
  updateApplicantDetails: (value: IApplicantDetails) => void;
  storeTemp: (value: IUser) => void;
  updateCurrentUser: (value: IUser) => void;
};
const AuthContext = createContext<initState>({} as unknown as initState);
export const AuthProvider = AuthContext.Provider;

export const getDefaultAuth = () => {
  try {
    const token = secureStorage.getItem(AUTH_TOKEN);
    return token;
  } catch (e) {
    return null;
  }
};

export const getSelectedBusiness = () => {
  try {
    const selectedBusinessId = secureStorage.getItemDecrypt(ZZiB);
    return selectedBusinessId;
  } catch (e) {
    return null;
  }
};
export const getDefaultSecret = (key: string) => {
  try {
    const userObj = secureStorage.getItem(key) as unknown as string;
    const decrypted = userObj && (cryptographyService().decrypt(userObj) as IUser);
    return decrypted;
  } catch (e) {
    return null;
  }
};
//On user logout remove token from localstorage
export const setLogout = () => {
  secureStorage.clearAll();
  secureStorage.removeItem(AUTH_TOKEN);
  secureStorage.removeItem(AUTH_USER);
  secureStorage.removeItem(ZZiB);
};

interface Props {
  children?: ReactChild | ReactChild[];
}

export const AuthProviderContainer: FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState<string>("");
  const [userObj, setuserObj] = useState<IUser | string>();
  const [encrypted, setEncryptedUser] = useState<string>("");
  const [currentUser, setCurrentUser] = useState<IUser>(initState.currentUser);
  const [applicantDetails, setapplicantDetails] = useState<IApplicantDetails>(initState.applicantDetails);
  const setAuthAndCache = (value: string) => {
    value ? secureStorage.storeItem(AUTH_TOKEN, value) : secureStorage.removeItem(AUTH_TOKEN);

    setAuth(value);
  };

  useEffect(() => {
    const defaultAuth = getDefaultAuth();
    if (defaultAuth) {
      setAuth(defaultAuth);
    }
  }, []);

  const setUserCredentials = (userObj: IUser) => {
    if (userObj && Object.keys(userObj).length > 1) {
      setuserObj(userObj);
      const encrypted = cryptographyService().encrypt(userObj);
      secureStorage.storeItem(AUTH_USER, encrypted);
      setEncryptedUser(encrypted);
    }
  };

  const getUserCredentials = (key: string) => {
    const userObj = secureStorage.getItem(key) as unknown as string;
    const decrypted = userObj && cryptographyService().decrypt(userObj);
    return decrypted;
  };

  const updateCurrentUser = (value: IUser) => {
    if (value) setUserCredentials(value);
    setCurrentUser((_currentUser) => ({ ..._currentUser, ...value }));
  };

  const updateApplicantDetails = (value: IApplicantDetails) => {
    if (value) setapplicantDetails(value);
  };

  const storeTemp = (value: IUser) => {
    if (value) secureStorage.storeItem(TEMP, JSON.stringify(value));
    setCurrentUser((temp) => ({ ...temp, ...value }));
  };

  return (
    <AuthProvider
      value={{
        auth,
        storeTemp,
        updateCurrentUser,
        getUserCredentials,
        currentUser,
        setAuthAndCache,
        setLogout,
        applicantDetails,
        updateApplicantDetails,
        setUserCredentials,
      }}
    >
      {children}
    </AuthProvider>
  );
};
export default AuthContext;
