import { api } from "../constants/apiConstants";
import { dummyRequestData } from "../constants/dummyRequests";
import { IAddCourseModel, IEditCourseModelRequest } from "../types/coursemodel";
import { IRequests } from "../types/requests";
import { returnBaseUrl } from "../utils/serviceUtils";
import Axios from "./AxiosInstance";

export const getAllCourseModelsApiService = (payload: IRequests = dummyRequestData) => {
  let newPayload  = dummyRequestData
  if(payload.pageIndex && payload.pageIndex !== null && payload.pageIndex !== undefined){
    newPayload = {
      ...newPayload,
      pageIndex : payload.pageIndex
    }
  }
  if(payload.searchWord && payload.searchWord !== null && payload.searchWord !== undefined){
    newPayload = {
      ...newPayload,
      searchWord : payload.searchWord
    }
  }
  if(payload.pageSize && payload.pageSize !== null && payload.pageSize !== undefined){
    newPayload = {
      ...newPayload,
      pageSize : payload.pageSize
    }
  }
  return Axios.post(returnBaseUrl(api.COURSEMODEL, api.GET_ALL_COURSE_MODELS), newPayload);
};

export const getCourseProgramDetailsApiService = (id: string) => {
  return Axios.get(returnBaseUrl(api.COURSEMODEL, api.GET_COURSE_MODEL_DETAIL_BY_ID, id));
};

export const addCourseModelApiService = (payload : IAddCourseModel) => {
  return Axios.post(returnBaseUrl(api.COURSEMODEL,api.CREATE_COURSE_MODEL),payload);
};


export const editCourseModelApiService = (payload: IEditCourseModelRequest, id: string) => {
  return Axios.put(returnBaseUrl(api.COURSEMODEL, `${api.UPDATE_COURSE_MODEL}/${id}`), payload);
};

export const deleteCourseModelApiService = (id: string) => {
  return Axios.delete(returnBaseUrl(api.COURSEMODEL, `${api.DELETE_COURSE_MODEL}/${id}`));
};