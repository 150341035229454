import { AxiosError } from "axios";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { IfrontendErrors } from "../types/response";
import { handleError } from "../utils/errorHandler";

const useHandleError = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const handleAppError = (axiosError: AxiosError, frontEndError: IfrontendErrors | null) => {
    const { error,type } = handleError(axiosError, null);
    if(type && type === "expired"){
      addToast(error, {
        appearance: 'error',
        autoDismiss: true,
      })
      history.push("/login")
    }else{
    if (typeof error === "string") {
      addToast(error, {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      addToast("An Internal Error occurred", {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  };

  return { handleAppError };
};

export default useHandleError;
