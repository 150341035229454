import { api } from "../constants/apiConstants";
import { dummyRequestData } from "../constants/dummyRequests";
import { IRequests } from "../types/requests";
import { IRoleRequest, IVerifyAccountRequest, ProfileApplicantRequest } from "../types/user";
import { ICreateAdminUserRequest, IEditAdminUserRequest, IForgotPasswordRequest } from "../types/user";
import { returnBaseUrl } from "../utils/serviceUtils";
import Axios from "./AxiosInstance";

export const getAllAdminUsersApiService = (payload: IRequests = dummyRequestData) => {
  let newPayload = dummyRequestData;
  if (payload.pageIndex && payload.pageIndex !== null && payload.pageIndex !== undefined) {
    newPayload = {
      ...newPayload,
      pageIndex: payload.pageIndex,
    };
  }
  if (payload.fromDate && payload.fromDate !== null && payload.fromDate !== undefined) {
    newPayload = {
      ...newPayload,
      fromDate: payload.fromDate,
    };
  }
  if (payload.toDate && payload.toDate !== null && payload.toDate !== undefined) {
    newPayload = {
      ...newPayload,
      toDate: payload.toDate,
    };
  }
  if (payload.searchWord && payload.searchWord !== null && payload.searchWord !== undefined) {
    newPayload = {
      ...newPayload,
      searchWord: payload.searchWord,
    };
  }
  if (payload.pageSize && payload.pageSize !== null && payload.pageSize !== undefined) {
    newPayload = {
      ...newPayload,
      pageSize: payload.pageSize,
    };
  }
  if (payload.filters && payload.filters !== null && payload.filters !== undefined && payload.filters.length > 0) {
    newPayload = {
      ...newPayload,
      filters: payload.filters,
    };
  }
  return Axios.post(returnBaseUrl(api.USER, api.GET_ALL_ADMIN_USERS), newPayload);
};

export const isUserAdminApiService = (ssoUserId: string) => {
  return Axios.get(returnBaseUrl(api.USER, api.IS_USER_ADMIN, ssoUserId));
};

export const addAdminUserApiService = (payload: ICreateAdminUserRequest) => {
  return Axios.post(returnBaseUrl(api.USER, api.PROFILE_ADMIN_USER), payload);
};
export const updateAdminUserApiService = (payload: FormData, id?: string) => {
  return Axios.put(returnBaseUrl(api.USER, `${api.UPDATE_USER}/${id}`), payload);
};
// export const updateAdminUserApiService = (payload: IEditAdminUserRequest) => {
//   return Axios.post(returnBaseUrl(api.USER, api.UPDATE), payload);
// };
export const updateUserPictureApiService = (payload: FormData) => {
  return Axios.post(returnBaseUrl(api.USER, api.UPDATE_USER_IMAGE), payload);
};
export const updateAdminUserDetailsApiService = (payload: FormData) => {
  return Axios.post(returnBaseUrl(api.USER, api.UPDATE_USER), payload);
};
export const profileApplicantApiService = (payload: ProfileApplicantRequest) => {
  return Axios.post(returnBaseUrl(api.USER, api.PROFILE_APPLICANT), payload);
};
export const getAdminUserByIdApiService = (id: string) => {
  return Axios.get(returnBaseUrl(api.USER, `${api.GET_USERS_BY_ID}/${id}`));
};
export const getApplicantsDetailsBySSOUserIdApiService = (id: string) => {
  return Axios.get(returnBaseUrl(api.APPLICANT, api.GET_APPLICANT_DETAILS_BY_SSO_SER_ID, id));
};

export const getApplicantsDetailsByEmailApiService = (email: string) => {
  return Axios.get(returnBaseUrl(api.APPLICANT, api.GET_APPLICANT_DETAILS_BY_EMAIL, email));
};

export const forgotPasswordApiService = (payload: IForgotPasswordRequest) => {
  return Axios.get(returnBaseUrl(`${api.USER}/${api.FORGOT_PASSWORD}`, api.ADMIN, `?email=${payload.email}`));
};
export const verifyAccountApiService = (payload: IVerifyAccountRequest) => {
  return Axios.post(returnBaseUrl(api.USER, api.VERIFY_ACCOUNT), payload);
};
export const removeUserFromRoleApiService = (payload: IRoleRequest) => {
  return Axios.post(returnBaseUrl(api.ROLE, api.REMOVE_USER_FROM_ROLE), payload);
};
export const addUserFromRoleApiService = (payload: IRoleRequest) => {
  return Axios.post(returnBaseUrl(api.ROLE, api.ADD_USER_FROM_ROLE), payload);
};
