import { Button, ButtonProps, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { CustomCircleLoader } from '../Loader/CustomCircleLoader';
import customButtonStyle from './customButtonStyle';
export interface CustomButtonProps extends ButtonProps {
    loading?: boolean,
    text?: string | undefined,
    width?: number,
    height?: number,
    default?: boolean,
    show?: boolean,
    fontSize?: number,
    fontSizeType?:'small' | 'medium' | 'large'
    className?:string,
    backgroundColor?:string,
    greyedOut?:boolean,
    borderRadius?:number
}
const CustomButton = ({ show = true,borderRadius=4,greyedOut,backgroundColor,fontSizeType='large', fontSize, loading, text,className, width, height=45, ...rest }: CustomButtonProps) => {
    const classes = customButtonStyle();
    const appTheme = useTheme()
    return (
        <>
            {show && <Button
                className={clsx(classes.root, classes.label,className)}
                classes={{
                    disabled: rest.disabled ? classes.disabled : classes.enabled,
                    root: rest.default ? classes.default : ''
                }}
                style={{
                    width: width,
                    height: height,
                    borderRadius,
                    fontSize:fontSizeType === 'small' ?12.5 :fontSizeType === 'medium' ? 13.5 : fontSizeType === 'large'? 15 : fontSize,
                    backgroundColor:greyedOut? appTheme.palette.grey[300]:backgroundColor
                }}
                disableElevation {...rest}>

                {text}{loading && <div style={{ marginTop: '6px' }} className="ms-2"><CustomCircleLoader /></div>}
            </Button>}
        </>
    );
};

export default CustomButton;