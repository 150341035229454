import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Pagination, { PaginationRenderItemParams } from "@material-ui/lab/Pagination";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import globalStyles from "styles/globalStyles";
import { IListMetaData } from "types/listtable";
import { IResponse } from "types/response";
import { initPager } from "../../../constants/dummyRequests";
import useHandleError from "../../../hooks/useHandleError";
import paginationStyles from "../../../styles/components/paginationStyles";
import { IPager } from "../../../types/Pagination";
import { IRequests } from "../../../types/requests";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    disableRipple: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  }),
);

interface StellasPaginationApiProps<T> {
  items?: T[];
  itemsCount: number;
  handleChangePageParent: (newPage: number, pageOfItems: any, pager: IPager) => void;
  searchData: any;
  extraData?: string;
  service: (searchData?: IRequests, extraData?: string) => Promise<AxiosResponse<IResponse<T[]>>>;
  updateSearch: (listMetaData: IListMetaData) => void;
  listMetaData?: IListMetaData;
  refresh?: boolean;
}
export default function VigilearnPaginationApi<T>({
  refresh,
  listMetaData,
  service,
  searchData,
  extraData,
  updateSearch,
  itemsCount,
  handleChangePageParent,
}: StellasPaginationApiProps<T>) {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pager, setPager] = useState<IPager>(initPager);
  const classes = globalStyles();
  const paginationClasses = paginationStyles();
  const { handleAppError } = useHandleError();
  const getPager = (
    totalPages: number,
    totalItems: number,
    currentPage: number = 1,
    pageSize: number,
    itemsCount: number,
  ): IPager => {
    // default to first page
    currentPage = currentPage;
    // debugger
    // default page size is 10
    pageSize = pageSize || itemsCount;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= itemsCount) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = itemsCount;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  };

  const handleChangePage = (event: React.ChangeEvent<unknown> | null, newPage: number) => {
    setPage(newPage);
    let newSearchData = {
      ...searchData,
      pageIndex: newPage,
    };

    if (extraData) {
      newSearchData = {
        ...newSearchData,
        extraData: extraData,
      };
    }

    updateSearch?.({ isError: false, isSearching: true });

    service(newSearchData)
      .then((res: AxiosResponse<IResponse<T[]>>) => {
        if (res.data.hasErrors) {
          updateSearch?.({ isError: true, isSearching: false });
          const newPager = getPager(
            res.data.payload.totalPages,
            res.data.payload.totalSize,
            newPage,
            searchData?.pageSize ?? rowsPerPage,
            searchData?.pageSize ?? rowsPerPage,
          );
          setPager(newPager);
          const pageOfItems = res.data.payload.items;
          // call change page function in parent component
          handleChangePageParent?.(newPage, pageOfItems, newPager);
        } else {
          const newPager = getPager(
            res.data.payload.totalPages,
            res.data.payload.totalSize,
            newPage,
            searchData?.pageSize ?? rowsPerPage,
            searchData?.pageSize ?? rowsPerPage,
          );
          setPager(newPager);
          const pageOfItems = res.data.payload.items;
          updateSearch?.({ isError: false, isSearching: false });

          // call change page function in parent component
          handleChangePageParent?.(newPage, pageOfItems, newPager);
        }
      })
      .catch((axiosError: AxiosError) => {
        handleAppError(axiosError, null);
        updateSearch?.({ isError: true, isSearching: false });
      });
  };

  const renderCustomPagination = (params: PaginationRenderItemParams) => {
    const { page, type, selected } = params;
    let children = null;
    if (type === "start-ellipsis" || type === "end-ellipsis") {
      children = "…";
    } else if (type === "page") {
      children = (
        <div className={`page ${selected && "page-selected"}`} {...params}>
          {page}
        </div>
      );
    } else {
      if (type === "previous") {
        children = (
          <div {...params} className="previous">
            Prev
          </div>
        );
      } else if (type === "next") {
        children = (
          <div {...params} className="next">
            Next
          </div>
        );
      }
    }
    return (
      <li key={_.uniqueId()} className={paginationClasses.paginationRoot}>
        {children}
      </li>
    );
  };

  useEffect(() => {
    handleChangePage(null, 1);
  }, []);

  useEffect(() => {
    if (refresh && refresh === true) {
      handleChangePage(null, 1);
    }
  }, [refresh]);

  useEffect(() => {
    const newSearchData = {
      ...searchData,
      searchWord: searchData.searchWord,
    };

    if (searchData && searchData.searching === true) {
      updateSearch?.({ isError: false, isSearching: true });
      console.log("newwwwwww", newSearchData);
      service(newSearchData)
        .then((res: AxiosResponse<IResponse<T[]>>) => {
          const pager = getPager(
            res.data.payload.totalPages,
            res.data.payload.totalSize,
            1,
            searchData?.pageSize ?? rowsPerPage,
            searchData?.pageSize ?? rowsPerPage,
          );
          setPager(pager);
          const pageOfItems = res.data.payload.items;
          updateSearch?.({ isError: false, isSearching: false });
          // call change page function in parent component
          handleChangePageParent?.(1, pageOfItems, pager);
        })
        .catch(() => {
          updateSearch?.({ isError: true, isSearching: false });
        });
    }
    if (searchData?.refresh === true) {
      updateSearch?.({ isError: false, isSearching: true });
      console.log("heyyyyyyyyyy", newSearchData);

      service(newSearchData)
        .then((res: AxiosResponse<IResponse<T[]>>) => {
          const pager = getPager(
            res.data.payload.totalPages,
            res.data.payload.totalSize,
            1,
            searchData?.pageSize ?? rowsPerPage,
            searchData?.pageSize ?? rowsPerPage,
          );
          setPager(pager);
          const pageOfItems = res.data.payload.items;
          updateSearch?.({ isError: false, isSearching: false });
          // call change page function in parent component
          handleChangePageParent?.(1, pageOfItems, pager);
        })
        .catch(() => {
          updateSearch?.({ isError: true, isSearching: false });
        });
    }
  }, [searchData]);

  return (
    <>
      {itemsCount > 10 && (
        <>
          {!listMetaData?.isError && (
            <div className={paginationClasses.rootPagination}>
              {pager.totalPages > 1 && (
                <div className="mt-2 mb-2" style={{}}>
                  Showing Page {pager.currentPage} of {pager.totalPages}
                </div>
              )}
              <div>
                <Pagination
                  count={pager?.pages.length}
                  page={page}
                  renderItem={renderCustomPagination}
                  onChange={handleChangePage}
                  className={classes.selectRootPagination}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
