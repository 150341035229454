import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Hidden, MenuItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import topnavStyles from "styles/topnavStyles";
import AuthContext from "../../context/AuthContext";
import { IUser } from "../../types/user";
import CustomIcon from "../ui/CustomIcon/CustomIcon";
import CustomMenuWrapper from "../ui/CustomMenuWrapper/CustomMenuWrapper";
interface TopAppBarProps {
  handleDrawerToggle?: () => void;
}
const TopAppBar: React.FC<TopAppBarProps> = ({ handleDrawerToggle }) => {
  const classes = topnavStyles();
  const { setLogout } = useContext(AuthContext);
  const { logout, user } = useAuth0<Omit<IUser, "gender">>();

  const handleLogout = () => {
    setLogout();
    logout({
      returnTo: process.env.REACT_APP_AUTH0_LOG_OUT_REDIRECT_URL as unknown as string,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
  };

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar>
        <div className="d-flex w-100 mb-3 justify-content-between align-items-center">
          <div className="">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </div>

          <div className="rightItems">
            <CustomMenuWrapper
              menuButton={
                <div className={classes.userMenu}>
                  <Avatar className="cursor-pointer" src={user?.picture} />
                  <Hidden xsDown>
                    <Typography variant="caption">
                      <strong>{`${user?.nickname}`}</strong>
                    </Typography>
                  </Hidden>
                  <CustomIcon className="fi-rr-caret-down" />
                </div>
              }
              hasMultiple={true}
            >
              <Link to={"/app/settings/"}>
                <MenuItem className={classes.menuItem}>
                  <CustomIcon className="fi-rr-settings" />
                  <span> Settings </span>
                </MenuItem>
              </Link>
              <Link to={"/app/profile"}>
                <MenuItem className={classes.menuItem}>
                  <CustomIcon className="fi-rr-user" />
                  <span> My Profile </span>
                </MenuItem>
              </Link>

              <MenuItem className={classes.menuItem} component={"div"} onClick={handleLogout}>
                <CustomIcon className="fi-rr-sign-out" />
                <span> Log out </span>
              </MenuItem>
            </CustomMenuWrapper>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};
export default TopAppBar;
